import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDashboardComponent } from './card-dashboard.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IconFlagComponent } from "../../../../../@hop/components/icon-flag/icon-flag.component";

@NgModule({
  declarations: [CardDashboardComponent],
  exports: [CardDashboardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatSnackBarModule,
    MatMenuModule,
    ClipboardModule,
    MatTooltipModule,
    TranslateModule,
    RouterModule,
    IconFlagComponent
  ]
})
export class CardDashboardModule { }
