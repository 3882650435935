<div (keyup.escape)="close()" [class.show]="show$ | async" class="search">
  <button (click)="close()"
    class="ltr:right-12 rtl:left-12 top-12 absolute"
    color="primary"
    mat-icon-button
    type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>

  <input #searchInput (keyup.enter)="search()" [formControl]="searchCtrl" class="search-input" [placeholder]="'search'|translate"/>
  <div class="search-hint">{{"_general.hit-enter-to-search" | translate}}</div>
</div>

@if (show$ | async) {
  <div (click)="close()" class="search-overlay"></div>
}

