import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TimeUtilsService } from 'src/@hop/services/timeUtils.service';
import { UserState } from 'src/@hop/state';
import { DeleteDialogComponent } from '../../availability-component/delete-dialog/delete-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/@hop/services/comments.service';
import { CommentModel } from 'src/@hop/models/comment.model';
import { EditCommentComponent } from '../edit-comment-component/edit-comment-component.component';
import { DialogRegisterClientComponent } from '../../auth/dialog-register-client/dialog-register-client.component';

@UntilDestroy()
@Component({
  selector: 'hop-full-comment',
  templateUrl: './full-comment.component.html',
  styleUrls: ['./full-comment.component.scss']
})
export class FullCommentComponent implements OnInit {
  @Input() comment: CommentModel;
  @Input() reviewUuid: string;
  showComments = false
  @Input() showProduct;
  @Input() replyToComment = false;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  user: UserModel;
  @Output() commentDeleted = new EventEmitter();
  username: string;
  review: any;

  constructor(
    public timeUtilsService: TimeUtilsService,
    private commentsService: CommentsService,
    private toastr: ToastrService,
    public translateService: TranslateService,

    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.username = this.route?.snapshot?.paramMap?.get('username') || this.username;
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = { ...user };
    });
  }
  ngOnInit(): void { }

  openEditDialogComment() {
    this.dialog
      .open(EditCommentComponent, {
        data: {
          body: this.comment?.body,
          uuid: this.comment?.uuid
        },
        panelClass: 'dialog-open-availability'
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value?.result) {
          this.comment = { ...this.comment, ...value?.result };

        }
      });
  }

  deleteComment(comment: CommentModel) {
    this.dialog
      .open(DeleteDialogComponent, {
        data: {
          name: comment?.body,
          content: this.translateService.instant('_comments.delete-comment-content'),
          dialogTitle: this.translateService.instant('_comments.delete-comment')
        },
        panelClass: 'dialog-open-availability'
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value?.confirmDelete === true) {
          this.commentsService.removeComment(this.comment?.uuid).subscribe({
            next: (result) => {
              this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.comment_deleted'), {
                timeOut: 3000,
                progressBar: true
              });
              this.commentDeleted.emit(this.comment); // Emit the event
              // this.router.navigate([`/products`]);
            },
            error: (error) => {
              if (error.error) {
              }
            }
          });
        }
      });
  }

  addReplyToComment(comment) {
    if (!this.comment._comments) {
      this.comment._comments = [];
    }
    this.comment._comments.unshift(comment);

  }

  replyToCommentToggle() {
    if (this.user?.uuid) {
      this.replyToComment = true;
    } else {
      this.dialog.open(DialogRegisterClientComponent, {
        panelClass: 'dialog-open-menu-action',
      });
    }
  }

  removeComment(comment: CommentModel) {
    this.comment._comments = this.comment._comments.filter((r) => r.uuid !== comment.uuid);
  }
  approveOrDisapproveComment(approve: boolean) {
    this.commentsService.approveComment(this.comment?.uuid, approve).subscribe({
      next: (result) => {
        this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_comments.comment_approved'), {
          timeOut: 3000,
          progressBar: true
        });
        this.comment = { ...this.comment, ...result };
      },
      error: (error) => {
        if (error.error) {
        }
      }
    });
  }
}
