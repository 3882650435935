import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { AuthenticationService, LayoutService } from '../../services';
import { ConfigService } from '../../config/config.service';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { FeatureEnum, UserModel, UserProfileModel } from '../../models';
import { ColorSchemeName } from '../../config/colorSchemeName';
import { HopConfig } from 'src/@hop/config/hop-config.interface';
import { NavigationLink } from 'src/@hop/interfaces/navigation-item.interface';
import { Select, Store } from '@ngxs/store';
import { AppHopState, UserState } from '../../state';
import { ColorSchemeSet } from '../../state';
import { AppState } from '../../../app/state/states/app-state.service';
import { environment } from '../../../environments/environment';
import { PublicProfileService } from '../../services/public-profile.service';
import { PublicProfileModel } from '../../models/public-profile.model';
import { OrdersService } from '../../services/orders.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'hop-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  @Input() mobileQuery: boolean;
  @Input() user: UserModel = null;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  config$: Observable<HopConfig> = this.configService.config$;

  @Select(UserState.selectUser) user$: Observable<ColorSchemeName>;
  @Select(AppHopState.selectBrand) brand$: Observable<string>;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map((config) => config.toolbar.user.visible));
  megaMenuOpen$: Observable<boolean> = of(false);

  userPublicProfile: UserProfileModel = null;
  cartCount: number = 0;
  isProfessionalHomeRoute: boolean;
  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    public navigationService: NavigationService,
    private popoverService: PopoverService,
    private publicProfileService: PublicProfileService,
    public authenticationService: AuthenticationService,
    public ordersService: OrdersService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.publicProfileService.userProfile$.subscribe((userPublicProfile) => {
      this.userPublicProfile = userPublicProfile;
    });
    this.ordersService.cart$.subscribe((order) => {
      // count all quantities from all the orderProducts in the order
      this.cartCount = order?.orderProducts?.reduce((acc, orderProduct) => acc + orderProduct.quantity, 0) || null;
    });

    // Trigger on route change
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ).subscribe((event) => {
      this.checkIfIsProfessionalHomeRoute(event['url']);
    });

    // Trigger on page refresh
    this.checkIfIsProfessionalHomeRoute(this.router.url);
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }
  checkIfIsProfessionalHomeRoute(url: string): void {
    const urlSplit = url.split('/');
    this.isProfessionalHomeRoute = urlSplit.length === 2 && urlSplit[1].startsWith('@');
  }


  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ]
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  openBasket(): void {
    this.layoutService.openBasket(0);
  }

  protected readonly FeatureEnum = FeatureEnum;
}
