import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'src/@hop/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from 'src/@hop/services';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormErrorsModule } from '../mat-form-errors/mat-form-errors.module';
import { UserEmailCodeComponent } from '../user-email-code/user-email-code.component';
import { ProfileService } from 'src/@hop/services/profile.service';
import { UserPhoneCodeComponent } from '../user-phone-code/user-phone-code.component';
import { PhoneInputComponent } from 'hop-calendar';

@UntilDestroy()
@Component({
  selector: 'add-phone',
  template: `
    @if (form) {
      <div [formGroup]="form" class="flex flex-col">
        <mat-form-field class="w-full flex flex-col">
          <phone-input
            inputPlaceholder="{{ '_contacts._label.phone' | translate }}"
            [preferredCountries]="preferredCountries"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            name="phone"
            formControlName="phone"
            #phone
          ></phone-input>
          <mat-error>
            <hop-mat-form-error control="phone"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>{{ '_edit_phone_email.phone-code' | translate }}</mat-label>
          <hop-user-phone-code #userEmailCodeRef [phoneToSend]="f?.phone?.value" formControlName="phoneCode"></hop-user-phone-code>
          <mat-error data-testid="error-phone-code">
            <hop-mat-form-error control="phoneCode"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
        @if (emailCodeRequired) {
          <p>
            {{ '_edit_phone_email.info_text_phone_add' | translate }} <span class="text-green">{{ email }}</span>
          </p>
        }
        @if (emailCodeRequired) {
          <mat-form-field class="w-full">
            <mat-label>{{ '_edit_phone_email.email-code' | translate }}</mat-label>
            <hop-user-email-code #userEmailCodeRef [emailToSend]="email" formControlName="emailCode"></hop-user-email-code>
            <mat-error data-testid="error-email-code">
              <hop-mat-form-error control="emailCode"></hop-mat-form-error>
            </mat-error>
          </mat-form-field>
        }
        <button data-testid="button-phone-add-submit" class="mt-4 self-end" mat-raised-button (click)="submit(form)" color="primary">
          {{ '_edit_phone_email.addPhone' | translate }}
        </button>
      </div>
    }
    `,
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatFormErrorsModule,
    UserEmailCodeComponent,
    ReactiveFormsModule,
    UserPhoneCodeComponent,
    PhoneInputComponent
]
})
export class AddPhoneComponent implements OnInit {
  user: UserModel;
  form: UntypedFormGroup;
  email: string;
  emailCodeRequired = true;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  protected preferredCountries: string[];
  @Output() onAddPhone = new EventEmitter();
  constructor(
    private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private formUtilsService: FormUtilsService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private store: Store,
    @Inject('environment') private environment: any
  ) {
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.email = user?.email;
    });
  }

  async ngOnInit() {
    // check if emailCode is required
    this.emailCodeRequired = await firstValueFrom(this.profileService.checkEmailCodeRequired());
    this.form = this.fb.group({
      phone: ['', Validators.required],
      phoneCode: ['', Validators.required],
      emailCode: ['', Validators.required]
    });
    if (!this.emailCodeRequired) {
      this.form.removeControl('emailCode');
    }
    this.preferredCountries = this.environment.appConfig.preferredPhoneCountryCodes;
  }

  submit(form) {
    if (!form.valid) {
      return;
    } else {
      this.profileService.addPhone(this.form?.value).subscribe({
        next: (result) => {
          this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.phone_added'), {
            timeOut: 3000,
            progressBar: true
          });
          this.onAddPhone.emit();
        },
        error: (error) => {
          if (error.error) {
            this.toastr.error(this.translateService.instant('_general.check_form_for_errors'), this.translateService.instant('_general.error'), {
              timeOut: 3000,
              progressBar: true
            });
            this.formUtilsService.setFormErrors(error.error, this.form);
          }
        }
      });
    }
  }
  get f() {
    return this.form.controls;
  }
}
