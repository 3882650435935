import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  standalone: true,
  selector: '[hopRouterLink]'
})
export class AppRouterLinkDirective implements OnInit {
  @Input('hopRouterLink') _uri: string;
  @Input('hopRouterLinkNoUnderline') _noUnderline: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {}

  async ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'cursor-pointer');
    const tagName = this.el.nativeElement.tagName.toLowerCase();
    const hasMatMenuItem = this.el.nativeElement.hasAttribute('mat-menu-item');

    if (tagName !== 'img' && !hasMatMenuItem && typeof this._noUnderline === 'undefined') {
      this.renderer.addClass(this.el.nativeElement, 'underline');
    }
    this.renderer.setAttribute(this.el.nativeElement, 'href', this._uri);
    this.el.nativeElement.addEventListener('click', (event: Event) => {
      // if _uri is a normal link and starts with http, then open it in a new tab
      if (this._uri.startsWith('http')) {
        window.open(this._uri, '_blank');
        return;
      }
      event.preventDefault();
      this.router.navigateByUrl(this._uri);
    });
  }
}
