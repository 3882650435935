import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/@hop/services';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfileService } from 'src/@hop/services/profile.service';
import { PhoneInputComponent } from 'hop-calendar';
import { MarkdownModule } from 'ngx-markdown';
import { AppPagesService } from 'src/@hop/services/app-pages.service';
@UntilDestroy()
@Component({
  selector: 'dialog-accept-terms',
  template: `
    <div class="">
      <div class="flex justify-between">
        <h1 mat-dialog-title class="text-lg">{{ '_general.terms-updated' | translate }}</h1>
        <div mat-dialog-actions>
          <!-- <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button> -->
        </div>
      </div>
      <div mat-dialog-content>
        <markdown ngPreserveWhitespaces class="ck-content w-full" [data]="page?.body"></markdown>
      </div>

      <div mat-dialog-actions class="flex justify-end">
        <button data-testid="button-refuse-terms" mat-button color="primary" (click)="refuseTerms()">
          {{ '_general.refuse-terms' | translate }}
        </button>
        <button data-testid="button-accept-terms" mat-raised-button color="primary" (click)="acceptTerms()">
          {{ '_general.accept-terms' | translate }}
        </button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslateModule, MatDialogModule, MarkdownModule]
})
export class DialogAcceptTermsComponent {
  page: any;

  constructor(
    public dialogRef: MatDialogRef<DialogAcceptTermsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appPagesService: AppPagesService,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    this.appPagesService.getAppPage('terms-and-conditions').subscribe((body) => {
      this.page = body;
    });
  }
  onNoClick(): void {
    // this.dialogRef.close();
  }
  refuseTerms() {
    this.authenticationService.logout();
  }
  acceptTerms() {
    this.profileService.agreeTermsProfile(this.data?.user?.uuid).subscribe((data) => {
      this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.terms_agreed'), {
        timeOut: 3000,
        progressBar: true
      });
      this.dialogRef.close();
    });
  }
}
