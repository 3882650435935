import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, firstValueFrom } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { ReviewModel } from 'src/@hop/models/review.model';
import { FormUtilsService } from 'src/@hop/services';
import { ReviewsService } from 'src/@hop/services/review.service';
import { UserState } from 'src/@hop/state';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/@hop/services/comments.service';
@Component({
  selector: 'hop-write-comment',
  templateUrl: './write-comment.component.html',
  styleUrls: ['./write-comment.component.scss']
})
export class WriteCommentComponent implements OnInit {
  isWriteReview = false;
  form;

  @Select(UserState.selectUser)
  user$: Observable<UserModel>;

  @Input() reviewUuid: string;
  @Input() commentParentUuid: string;
  @Input() review: ReviewModel;
  @Output() submittedComment = new EventEmitter();
  @Output() onHideWriteComment = new EventEmitter();
  isEditMode = false;
  constructor(
    private fb: UntypedFormBuilder,
    private commentService: CommentsService,
    private toastr: ToastrService,
    private formUtilsService: FormUtilsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // if (this.review) {
    //   this.isEditMode = true;
    // }
    this.form = this.fb.group({
      body: ['', Validators.required]
    });
  }

  hideWriteComment() {
    this.onHideWriteComment.emit();
  }
  async submitComment() {


    const commentData: any = { ...this.form.value, review: { uuid: this.reviewUuid } };
    if (this.commentParentUuid) {
      commentData.commentParent = { uuid: this.commentParentUuid };
    }
    return await firstValueFrom(this.commentService.createComment(commentData))
      .then((result) => {
        this.form.reset();
        this.hideWriteComment();
        this.submittedComment.next(result);
        this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.review_added'), {
          timeOut: 3000,
          progressBar: true
        });
        // this.router.navigate([`m/${result.uuid}`]);
      })
      .catch((error) => {
        if (error?.error) {
          this.formUtilsService.setFormErrors(error.error, this.form);
          this.toastr.error(error?.error?.message, this.translateService.instant('_general.review_error_sending'), {
            timeOut: 10000,
            progressBar: true
          });
        }
      });
  }
}
