import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ContactModel } from '../models/contact.model';
import { PaginationFilterOptions } from '../models/pagination.model';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  endpoint = `${this.environment.apiUrl}/profile`;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment
  ) {}

  removePhone(emailCode): Observable<any> {
    return this.http.post<any>(this.endpoint + '/remove-phone', emailCode).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  addPhone(phoneDetails): Observable<any> {
    return this.http.post<any>(this.endpoint + '/add-phone', phoneDetails).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  addEmail(emailDetails): Observable<any> {
    return this.http.post<any>(this.endpoint + '/add-email', emailDetails).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  agreeTermsProfile(uuid) {
    return this.http.patch<any>(this.endpoint, { _termsAccepted: true, uuid: uuid }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  checkEmailCodeRequired(): Observable<boolean> {
    return this.http.get<any>(this.endpoint + '/check-email-code-required').pipe(
      map((data) => {
        return !(data === false || data === 'false');
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
