<div
  class="psm__progress-bar"
  role="progressbar"
  [passwordStrength]="passwordStrength"
  [numberOfProgressBarItems]="numberOfProgressBarItems"
  [colors]="colors"
  >
  <div class="psm__progress-bar-items"></div>
  <div class="psm__progress-bar-overlay"></div>
</div>
@if (enableFeedback && feedback) {
  @if (feedback.warning) {
    <small class="psm__feedback">
      {{ feedback.warning }}
    </small>
  }
  @if (feedback.suggestions && feedback.suggestions.length) {
    <small
      class="psm__suggestion"
      >
      @for (suggestion of feedback.suggestions; track suggestion; let isLast = $last) {
        {{ suggestion }}{{ isLast ? '' : ' ' }}
      }
    </small>
  }
}
