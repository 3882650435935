import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { environment } from '../../environments/environment';

@Pipe({
  standalone: true,
  name: 'safeHtmlPipe'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    value = DOMPurify.sanitize(value, {
      ALLOWED_TAGS: [
        'iframe',
        'img',
        'br',
        'p',
        'h1',
        'h2',
        'h3',
        'div',
        'b',
        'span',
        'strong',
        'blockquote',
        'pre',
        'ul',
        'li',
        'ol',
        'a',
        'u',
        's',
        'sub',
        'sup',
        'width',
        'height',
        'figure',
        'pre',
        'code',
        'table',
        'thead',
        'tr',
        'td',
        'th',
        'oembed',
        'mark',
        'i',
        'audio',
        'source'
      ],
      ADD_ATTR: [
        'allow',
        'allowfullscreen',
        'frameborder',
        'scrolling',
        'src',
        'class',
        'spellcheck',
        'href',
        'style',
        'allow',
        'allowfullscreen',
        'title',
        'frameborder',
        'url',
        'data-oembed-url',
        'target',
        'controls',
        'type'
      ]
    });
    //
    //value = value.split('<a ').join('<a target="_blank"');

    // replace all that are local links with self
    //const regexLocalLinks = new RegExp(`(<a )(href="(${environment.mainUrl}|\/)[^"]+">)`, 'gm');
    //value = value.replace(regexLocalLinks, '$1  target="_self" $2');

    // replace all remaining with _blank
    //value = value.replace(/(<a )(href="[^"]+">)/gm, '$1   target="_blank" $2');
    value = this.addMissingTargetAttributes(value);

    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  // AI is awesome
  addMissingTargetAttributes(html: string): string {
    // Detect a tags without a target attribute
    const regex = /<a (?!.*?target=['"])([^>]*?)href=['"]((https?:\/\/[^'"]+)|\/[^'"]*)['"]([^>]*?)>/g;

    // The replacement function to decide on the target based on the href
    const replaceFunction = (match: string, pre: string, href: string, protocol: string, post: string) => {
      // Determine target based on href
      let target = '_blank'; // Default to external links
      if (href.startsWith(environment.mainUrl) || href.startsWith('/')) {
        target = '_self'; // Internal links
      }
      return `<a ${pre}href="${href}" target="${target}" ${post}>`;
    };

    // Perform replacements
    return html.replace(regex, replaceFunction);
  }
}
