import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError, of } from 'rxjs';
import { PublicProfileModel } from '../models/public-profile.model';
import { PaginationFilterOptions } from '../models/pagination.model';

@Injectable({ providedIn: 'root' })
export class OfferAppointmentService {
  endpoint = `${this.environment.apiUrl}/offerAppointments`;

  constructor(private http: HttpClient, @Inject('environment') private environment) { }

  getUserOfferAppointments(): Observable<any> {
    return this.http.get<any>(this.endpoint).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getMyOfferAppointmentsWithPagination(
    options: PaginationFilterOptions = {
      filter: {}, sortName: '', sortOrder: 'asc', page: 1, limit: 10
    }
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/offset', {
        params: new HttpParams()
          .set('filter', JSON.stringify(options.filter))
          .set('sortName', options.sortName)
          .set('sortOrder', options.sortOrder)
          .set('page', options.page)
          .set('limit', options.limit)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getUserOfferAppointmentByUuid(uuid: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/uuid/${uuid}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getDateIntervals(uuid: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/date-intervals/uuid/${uuid}`).pipe(
      map((dateIntervals: []) => {
        return dateIntervals.map(({ dateStart, dateEnd }) => {
          return { dateStart: new Date(dateStart), dateEnd: new Date(dateEnd) };
        });
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getUserOfferAppointmentByCustomUrl(username: string, customUrl: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/custom-url/${username}/${customUrl}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getUserOfferAppointmentBySlug(slug: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/slug/${slug}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  putOfferAppointment(offerAppointment): Observable<any> {
    if (offerAppointment?.customUrl) offerAppointment.customUrl = offerAppointment.customUrl.toLowerCase();
    return this.http.put<any>(this.endpoint, offerAppointment).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  patchOfferAppointment(offerAppointment): Observable<any> {
    if (offerAppointment?.customUrl) offerAppointment.customUrl = offerAppointment.customUrl.toLowerCase();

    return this.http.patch<any>(this.endpoint, offerAppointment).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getAutocomplete(filter = {}, sortName = '', sortOrder = 'asc', limit = 5, cursorUuid): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/cursor', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('limit', limit)
          .set('cursorUuid', cursorUuid.toString())
        // context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  validateCustomUrl(customUrl): Observable<any> {
    return this.http.post<any>(this.endpoint + '/validate/custom-url', { customUrl: customUrl }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  deleteOfferAppointment(uuid): Observable<any> {
    return this.http.delete<any>(this.endpoint, { body: { uuid: uuid } }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  setPriorities(firstUuid: string, secondUuid: string, isFirst: boolean = false): Observable<any> {
    return this.http.post<any>(this.endpoint + '/set-priorities', { firstUuid, secondUuid, isFirst }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
