import { Component } from '@angular/core';
import {
    UntypedFormGroup
} from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { UserState } from 'src/@hop/state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SetPasswordModule } from 'src/app/pages/pages/set-password/set-password.module';

@UntilDestroy()
@Component({
    selector: 'dialog-set-password',
    template: `
    <div class="">
  <div class="flex justify-between">
    <h1 mat-dialog-title class="text-lg">{{ '_authentication.set_password' | translate }}</h1>
    <div mat-dialog-actions>
      <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div>
  <div mat-dialog-content>
       <hop-set-password (onSetPassword)="setPassword()"></hop-set-password>
  </div>
 </div>
`,
    standalone: true,
    imports: [MatIconModule, MatButtonModule, TranslateModule, SetPasswordModule,
        MatDialogModule
    ]
})
export class DialogSetPasswordComponent {
    user: UserModel;
    form: UntypedFormGroup;
    email: string;
    @Select(UserState.selectUser)
    user$: Observable<UserModel>;
    protected preferredCountries: string[];

    constructor(
        public dialogRef: MatDialogRef<DialogSetPasswordComponent>,

    ) {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    setPassword() {
        this.dialogRef.close({ isPasswordSet: true });
    }

}
