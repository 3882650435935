<div class="card-with-bg flex flex-col justify-between group bg-foreground border w-full h-40 p-4 rounded-md">
  <div class="card-bg-image" [ngStyle]="{
      'background-image': 'url(' + offerAppointment?.imageFile?.url + ') '
    }"></div>
  <div class="card-content">
    <div class="flex space-x-3 items-center">
      <div class="self-start rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10">
        <mat-icon svgIcon="mat:local_offer"></mat-icon>
      </div>
      <div class='space-y-1 break-normal overflow-hidden'>
        <a (click)="editOfferAppointment(offerAppointment)" data-testid="button-link-offerAppointment-name-edit"
        class="line-clamp-2 text-base cursor-pointer">{{ offerAppointment.name }}</a>
        @if (!offerAppointment._productsCount) {
          <p class="text-sm">{{
            timeUtilsService.transformMinutes(offerAppointment?.durationMinutes,
          translateService).text }}</p>
        }
        @if (offerAppointment._productsCount) {
          <p class="text-sm">{{
            '_service.count-service'+(offerAppointment._productsCount>1?'s':'') |
          translate:{count:offerAppointment._productsCount} }}</p>
        }
        @if (!offerAppointment.showOnProfile) {
          <div class='flex flex-row items-center space-x-1'>
            <mat-icon>visibility_off</mat-icon>
            <p class="text-sm"> {{ '_offerAppointment.private-offer' | translate }}</p>
          </div>
        }
      </div>
    </div>
    <div class="flex justify-between">
      <hop-icon-flag [code]="offerAppointment?.lang?.code"></hop-icon-flag>
      <div class="flex space-x-4 md:hidden md:group-hover:flex h-4">
        <div class="hidden md:block space-x-4">
          <a [routerLink]="offerAppointment?._uri" target="_blank" matTooltip="{{'_general.view-offer' | translate}}"
            matTooltipPosition="above" class="rounded-full cursor-pointer" data-testid="button-card-view">
            <mat-icon svgIcon="mat:preview"></mat-icon>
          </a>
          <button data-testid="button-card-edit" matTooltip="{{ '_offerAppointment.edit-1-1-offer' | translate }}"
            matTooltipPosition="above" (click)="editOfferAppointment(offerAppointment)"
            class="rounded-full cursor-pointer">
            <mat-icon svgIcon="mat:edit"></mat-icon>
          </button>
        </div>

        <div>
          <button data-testid="button-card-more" #t="matMenuTrigger" [matMenuTriggerFor]="beforeMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #beforeMenu="matMenu" xPosition="before">
            <div class="block md:hidden">
              <a [routerLink]="offerAppointment?._uri" target="_blank" mat-menu-item>

                <mat-icon svgIcon="mat:preview"></mat-icon>
                <span>{{'_general.view-offer' | translate}}</span>
              </a>
              <button (click)="editOfferAppointment(offerAppointment)" mat-menu-item>
                <mat-icon svgIcon="mat:edit"></mat-icon>
                <span>{{ '_general.edit' | translate }}</span>
              </button>

            </div>
            <button data-testid="button-card-copy-offer-link" (click)=" openSnackbarCopiedLink('public')"
              [cdkCopyToClipboard]="environment.mainUrl + offerAppointment?._uri" mat-menu-item>
              <mat-icon svgIcon="mat:link"></mat-icon>
              <span>{{ '_offerAppointment.copy-custom-link' | translate }}</span>
            </button>
            <button data-testid="button-card-delete" (click)="deleteOfferAppointment(offerAppointment)" mat-menu-item>
              <mat-icon svgIcon="mat:receipt"></mat-icon>
              <span>{{ '_general.delete' | translate }}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>