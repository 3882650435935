import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCommentComponent } from './full-comment.component';
import { MatButtonModule } from '@angular/material/button';
import { StarsRatingModule, StarsRatingViewModule } from 'hop-calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WriteCommentComponentModule } from '../write-comment/write-comment.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppRouterLinkDirective } from '../../../../../@hop/components/router-link/router-link.directive';
import { ViewMoreModule } from '../../../../../@hop/components/directives/view more/view-more-directive.module';
import { AllCommentsModule } from '../all-comments/all-comments.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FullCommentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    StarsRatingViewModule,
    WriteCommentComponentModule,
    FormsModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    ViewMoreModule,
    AppRouterLinkDirective,
  ],
  exports: [FullCommentComponent]
})
export class FullCommentModule { }
