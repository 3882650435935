import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WriteCommentComponent } from './write-comment.component';
import { MatButtonModule } from '@angular/material/button';
import { StarsRatingModule } from 'hop-calendar';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormErrorsModule } from 'src/@hop/components/mat-form-errors/mat-form-errors.module';
import { TranslateModule } from '@ngx-translate/core';
import { FastSignInGlobalComponent } from '../../auth/fast-sign-in-global.component';

@NgModule({
  declarations: [WriteCommentComponent],
  exports: [WriteCommentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    StarsRatingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatFormErrorsModule,
    FastSignInGlobalComponent,
    TranslateModule
  ]
})
export class WriteCommentComponentModule { }
