import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/@hop/services/layout.service';
import { FeatureEnum } from '../../models';

@Component({
  selector: 'app-hop-debug-tool',
  templateUrl: './hop-debug-tool.component.html',
  styleUrls: ['./hop-debug-tool.component.scss']
})
export class HopDebugToolComponent implements OnInit {
  activate: boolean = false;
  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void { }

  // setFeatureValue(name, value) {
  //   this.store.dispatch(new FeatureSet(name, value));
  // }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  protected readonly FeatureEnum = FeatureEnum;
}
