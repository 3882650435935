import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { StarsRatingModule } from 'hop-calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormErrorsModule } from 'src/@hop/components/mat-form-errors/mat-form-errors.module';
import { EditReviewComponentComponent } from './edit-review-component.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EditReviewComponentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    StarsRatingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatFormErrorsModule,
    MatDialogModule,
    TranslateModule
  ],
  exports: [EditReviewComponentComponent]
})
export class EditReviewComponentModule {}
