<div class="sticky top-0 z-40 p-2 flex items-center justify-between bg-foreground shadow-lg">
  <p class="font-semibold text-sm">{{'_basket.basket'|translate}}</p>
  <button data-testid="button-basket-close" (click)="layoutService.closeBasket()"
    mat-icon-button><mat-icon>clear</mat-icon></button>
  </div>

  <div class="pb-6 mt-6 flex flex-col  justify-between flex-1 h-max">

    @if (products?.length>0) {
      <div class="px-1 flex flex-col space-y-4">
        @for (product of products; track product) {
          <hop-product-order-card (cartChanged)="getBasket()" [orderProduct]="product"
          ></hop-product-order-card>
        }
      </div>
    }
    @if (products?.length === 0) {
      <div class="px-4 text-center flex flex-col space-y-2">
        <p class=" text-sm font-semibold">{{'_basket.no-items-in-basket'|translate}}
        </p>
        <button type='button' (click)="layoutService.closeBasket()">{{'_basket.continue-shopping'|translate}}</button>
      </div>
    }
  </div>
  @if (products?.length>0) {
    <div class="sticky bottom-0 py-2 flex flex-col bg-foreground space-y-2">
      <hop-checkout-button class="m-auto self-center"></hop-checkout-button>
      <!--<button type="button"
      class="m-auto text-white cursor-pointer self-center inline-block rounded bg-black dark:bg-white dark:text-black-always px-4 py-2 text-sm font-medium uppercase leading-normal ">
      {{'_basket.checkout-now'|translate}}
    </button>-->
    <button (click)="goToProductsAndCloseBasket()" type="button">
      {{'_basket.continue-shopping'|translate}}
    </button>
  </div>
}
