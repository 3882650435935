import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'src/@hop/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from 'src/@hop/services';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormErrorsModule } from "../mat-form-errors/mat-form-errors.module";
import { UserEmailCodeComponent } from '../user-email-code/user-email-code.component';
import { ProfileService } from 'src/@hop/services/profile.service';
import { UserPhoneCodeComponent } from '../user-phone-code/user-phone-code.component';
import { PhoneInputComponent } from 'hop-calendar';
@UntilDestroy()
@Component({
    selector: 'add-email',
    template: `
   <div [formGroup]="form" class="flex flex-col w-full">
        <mat-form-field class="w-full">
        <mat-label>{{ '_label.email' | translate }}</mat-label>

        <mat-icon class="mr-2" matPrefix svgIcon="mat:mail"></mat-icon>
        <input formControlName="email" matInput required />
        <mat-error>
          <hop-mat-form-error control="email"></hop-mat-form-error>
        </mat-error>
      </mat-form-field>

           <mat-form-field class="w-full">
                <mat-label>{{ '_edit_phone_email.email-code' | translate }}</mat-label>
                <hop-user-email-code #userEmailCodeRef [emailToSend]="f?.email.value" formControlName="emailCode"></hop-user-email-code>
                <mat-error>
                    <hop-mat-form-error control="emailCode"></hop-mat-form-error>
                </mat-error>
                </mat-form-field>
    <p> {{ '_edit_phone_email.info_text_email_add' | translate }} <span class="text-green">{{phone}}</span></p>

           <mat-form-field class="w-full">
                <mat-label>{{ '_edit_phone_email.phone-code' | translate }}</mat-label>
                <hop-user-phone-code #userEmailCodeRef [phoneToSend]="phone" formControlName="phoneCode"></hop-user-phone-code>
                <mat-error>
                    <hop-mat-form-error control="phoneCode"></hop-mat-form-error>
                </mat-error>
                </mat-form-field>
           
             


        <button data-testid="button-email-add-submit" class="mt-4 self-end" mat-raised-button (click)="submit(form)" color="primary">{{ '_edit_phone_email.addEmail' | translate }}</button>
</div>
`,
    standalone: true,
    imports: [FormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, TranslateModule, MatFormErrorsModule, UserEmailCodeComponent, ReactiveFormsModule, UserPhoneCodeComponent, PhoneInputComponent],

})
export class AddEmailComponent implements OnInit {
    user: UserModel;
    form: UntypedFormGroup;
    phone: string;
    @Select(UserState.selectUser)
    user$: Observable<UserModel>;
    protected preferredCountries: string[];
    @Output() onAddEmail = new EventEmitter();
    constructor(
        private fb: UntypedFormBuilder,
        private profileService: ProfileService,
        private formUtilsService: FormUtilsService,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private store: Store,
        @Inject('environment') private environment: any

    ) {
        this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user;
            this.phone = user?.phone;
        });
    }


    ngOnInit() {
        this.form = this.fb.group({
            email: ['', Validators.required],
            emailCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            phoneCode: ['', Validators.required],
        });
        this.preferredCountries = this.environment.appConfig.preferredPhoneCountryCodes;
    }

    submit(form) {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.toastr.error(this.translateService.instant('_general.complete_all_fields'), this.translateService.instant('_general.error'), {
                timeOut: 3000,
                progressBar: true
            });
            return;
        } else {
            this.profileService.addEmail(form?.value).subscribe({
                next: (result) => {
                    this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.email_added'), {
                        timeOut: 3000,
                        progressBar: true
                    });
                    this.onAddEmail.emit();
                },
                error: (error) => {
                    if (error.error) {
                        this.toastr.error(this.translateService.instant('_general.check_form_for_errors'), this.translateService.instant('_general.error'), {
                            timeOut: 3000,
                            progressBar: true
                        });
                        this.formUtilsService.setFormErrors(error.error, form);
                    }
                }
            });
        }
    }
    get f() {
        return this.form.controls;
    }
}
