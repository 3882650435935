import { Component, Inject } from '@angular/core';
import {
    UntypedFormGroup
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { UserState } from 'src/@hop/state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DeletePhoneComponent } from '../delete-phone/delete-phone.component';
@UntilDestroy()
@Component({
    selector: 'dialog-delete-phone',
    template: `
    <div class="">
  <div class="flex justify-between">
    <h1 mat-dialog-title class="text-lg">{{ '_edit_phone_email.enter-the-code-you-received-at-email' | translate }}</h1>
    <div mat-dialog-actions>
      <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div>
  <div mat-dialog-content>
        <delete-phone  (onDeletePhone)="deletePhone()"></delete-phone>
  </div>
 
</div>
`,
    standalone: true,
    imports: [MatIconModule, MatButtonModule, TranslateModule, MatDialogModule, DeletePhoneComponent]
})
export class DialogDeletePhoneComponent {
    user: UserModel;
    form: UntypedFormGroup;
    email: string;
    @Select(UserState.selectUser)
    user$: Observable<UserModel>;

    constructor(
        public dialogRef: MatDialogRef<DialogDeletePhoneComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,


    ) {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }


    deletePhone() {
        this.dialogRef.close();
    }
}
