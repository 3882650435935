@if (form) {
<div [formGroup]="form" class="card-generic">
  <!-- <div class="flex flex-col space-y-5 md:flex-row md:space-y-0 md:justify-between"> -->
  @if (!isWriteReview) {
  <div class="flex flex-col sm:flex-row w-full justify-between space-y-2">
    <div class="flex flex-col space-y-">
      <p class="font-bold">{{"_reviews.tell-people-what-you-think" | translate }}</p>
      <p>{{"_reviews.help-others-by-sharing-your-experience-with-this-company" | translate }}</p>
    </div>
    <button class="w-full sm:w-auto" type="button" (click)="writeAReviewOrCancel()" mat-raised-button
      color="primary">{{"_reviews.write-a-review" | translate }}</button>
  </div>
  }
  @if (isWriteReview && (user$ | async)) {
  <div class="flex flex-col md:flex-row md:justify-between w-full">
    <div class="flex flex-col space-y-6">
      <p class="font-bold">{{"_reviews.your-star-rating" | translate }}</p>
      <app-stars-rating formControlName="rating"></app-stars-rating>
    </div>
    <div class="flex flex-col w-full md:w-2/3">
      <div class="flex flex-col">
        <p *ngIf="reviewQuestions?.profileReviewQuestions"
          [innerHTML]='(reviewQuestions?.profileReviewQuestions) | safeHtmlPipe' hopProcessLinksToRouter
          class='ck-content p-0 overflow-y-hidden'>
        </p>
        <p *ngIf="reviewQuestions?.productReviewQuestions"
          [innerHTML]='(reviewQuestions?.productReviewQuestions) | safeHtmlPipe' hopProcessLinksToRouter
          class='ck-content p-0 overflow-y-hidden'>
        </p>
      </div>
      <p class="text-lg font-bold">{{"_reviews.your-review" | translate }}</p>
      <mat-form-field class="">
        <textarea rows="5" placeholder='{{"_reviews._placeholder.body" | translate }}'
          data-testid="textarea-reason-cancel" matInput formControlName="body">
            </textarea>
        <mat-error><hop-mat-form-error control="body"></hop-mat-form-error></mat-error>
      </mat-form-field>
      <div class="self-start flex space-x-3">
        <button [disabled]="form?.invalid" type="button" class="px-5" style="height: 50px" (click)="submitReview()"
          mat-raised-button color="primary">
          {{"_reviews.submit-review" | translate }}
        </button>
        <button type="button" class="px-5" style="height: 50px" (click)="writeAReviewOrCancel()" mat-stroked-button
          color="primary">{{"_general.cancel" | translate }}</button>
      </div>
    </div>

  </div>
  }
  @if (!(user$ | async) && isWriteReview) {
  <hop-fast-sign-in-global class="w-full"></hop-fast-sign-in-global>
  }
</div>
}