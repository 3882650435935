<div class="">
  <div class="flex justify-between">
    @if (data?.setPassword) {
      <h1 mat-dialog-title class="text-lg">{{ '_menu-user.set-password' | translate }}</h1>
    }
    @if (data?.verifyEmail) {
      <h1 mat-dialog-title class="text-lg">{{'_menu-user.verify-email' | translate }}</h1>
    }
    <div mat-dialog-actions>
      <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div>
  <div class="my-4" mat-dialog-content>
    @if (data?.setPassword) {
      <hop-set-password></hop-set-password>
    }
    @if (data?.verifyEmail) {
      <hop-verify-email></hop-verify-email>
    }
  </div>
</div>