@if (config$ | async; as setting) {
  <div class="config-panel">
    <h2 class="headline mb-4">
      <mat-icon class="mr-3" svgIcon="mat:settings"></mat-icon>
      <span>Configuration</span>
    </h2>
    <div class="section">
      <h5 class="subheading">THEME BASE</h5>
      <div (click)="isDark ? disableDarkMode() : enableDarkMode()"
        *ngrxLet="isDark$; let isDark"
        class="rounded-full mt-2 flex items-center cursor-pointer relative bg-contrast-black text-contrast-white select-none"
        matRipple>
        <div class="m-2 h-6 w-6 rounded-full">
          @if (isDark) {
            <mat-icon svgIcon="mat:check"></mat-icon>
          }
          @if (!isDark) {
            <mat-icon svgIcon="mat:close"></mat-icon>
          }
        </div>
        <p class="ml-1 font-medium text-sm">DARK MODE</p>
      </div>
    </div>
    <div class="section">
      <h5 class="subheading">THEME COLORS</h5>
      @for (color of colorVariables | keyvalue; track color) {
        <div (click)="selectColor(color.value)"
          [class.selected]="isSelectedColor(color.value)"
          [style.backgroundColor]="color.value.light"
          [style.color]="color.value.default"
          class="hop-color-picker rounded-full mt-2 flex items-center cursor-pointer relative"
          matRipple>
          <div [style.backgroundColor]="color.value.default"
            class="m-2 h-6 w-6 rounded-full">
            @if (isSelectedColor(color.value)) {
              <mat-icon svgIcon="mat:check"></mat-icon>
            }
          </div>
          <p class="ml-1 font-medium text-sm">{{ color.key | uppercase }}</p>
        </div>
      }
    </div>
    <div class="section">
      <h5 class="subheading">PRE-BUILT STYLES</h5>
      @for (config of configs; track config; let first = $first) {
        <div
          [class.mt-6]="!first"
          class="rounded">
          <div class="layout-image rounded overflow-hidden relative hover:bg-hover shadow-md">
            <img [src]="config.imgSrc" class="w-full block">
            <div class="layout-image-overlay flex items-center justify-center gap-1">
              <button (click)="setConfig(config.id, ColorSchemeName.light)"
                class="bg-contrast-white text-contrast-black flex-none !min-w-0"
                mat-raised-button
                type="button">LIGHT
              </button>
              <button (click)="setConfig(config.id, ColorSchemeName.default)"
                color="primary"
                class="flex-none !min-w-0"
                mat-raised-button
                type="button">DEFAULT
              </button>
              <button (click)="setConfig(config.id, ColorSchemeName.dark)"
                class="bg-contrast-black text-contrast-white flex-none !min-w-0"
                mat-raised-button
                type="button">DARK
              </button>
            </div>
          </div>
          <div class="text-center body-2 mt-2">{{ config.name }}</div>
        </div>
      }
    </div>
    <div class="section">
      <h5 class="subheading">ROUNDED CORNERS</h5>
      <div class="grid grid-cols-4 gap-4">
        @for (borderRadius of roundedCornerValues; track borderRadius) {
          <div (click)="selectBorderRadius(borderRadius)"
            [class.border-gray-300]=""
            [ngClass]="{ 'border-gray-300': isSelectedBorderRadius(borderRadius, setting), 'border-transparent': !isSelectedBorderRadius(borderRadius, setting) }"
            class="aspect-square bg-primary/10 flex justify-end items-end cursor-pointer border-2 hover:border-gray-200 group trans-ease-out rounded-tl">
            <div [ngClass]="{ 'bg-primary border-primary': isSelectedBorderRadius(borderRadius, setting), 'bg-foreground border group-hover:bg-app-bar': !isSelectedBorderRadius(borderRadius, setting) }"
              [style.border-top-left-radius]="borderRadius.value + borderRadius.unit"
              class="h-7 w-7 border-2 trans-ease-out"
            style="margin-bottom: -2px; margin-right: -2px"></div>
          </div>
        }
      </div>
    </div>
    <div class="section">
      <h5 class="subheading">BUTTON STYLE</h5>
      <div class="flex justify-between gap-4">
        <button (click)="selectButtonStyle(undefined)"
          [ngClass]="{ '!bg-transparent !border-solid !border-primary !text-primary': !isSelectedButtonStyle(undefined, setting), '!border-transparent': isSelectedButtonStyle(undefined, setting) }"
          class="rounded flex-1 border-2 border-solid"
          color="primary"
          mat-flat-button
          type="button">
          INHERIT
        </button>
        <button (click)="selectButtonStyle(roundedButtonValue)"
          [ngClass]="{ '!bg-transparent !border-solid !border-primary !text-primary': !isSelectedButtonStyle(roundedButtonValue, setting), '!border-transparent': isSelectedButtonStyle(roundedButtonValue, setting) }"
          class="rounded-full flex-1 border-2 border-solid"
          color="primary"
          mat-flat-button
          type="button">
          ROUNDED
        </button>
      </div>
    </div>
    <div class="section">
      <h5 class="subheading">Layout</h5>
      <div class="section-content">
        <h5 class="subheading">Orientation</h5>
        <mat-slide-toggle (change)="layoutRTLChange($event)" [checked]="isRTL$ | async">RTL</mat-slide-toggle>
      </div>
    </div>
    <div class="section">
      <h5 class="subheading">Toolbar</h5>
      <div class="section-content">
        <h5 class="subheading">Position</h5>
        <mat-radio-group (change)="toolbarPositionChange($event)"
          [value]="setting.toolbar.fixed ? 'fixed' : 'static'"
          class="flex flex-col gap-3">
          <mat-radio-button value="fixed">Fixed</mat-radio-button>
          <mat-radio-button value="static">Static</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="section">
      <h5 class="subheading">Footer</h5>
      <div class="section-content">
        <mat-slide-toggle (change)="footerVisibleChange($event)" [checked]="setting.footer.visible">Visible
        </mat-slide-toggle>
        <h5 class="subheading">Position</h5>
        <mat-radio-group (change)="footerPositionChange($event)"
          [value]="setting.footer.fixed ? 'fixed' : 'static'"
          class="flex flex-col gap-3">
          <mat-radio-button value="fixed">Fixed</mat-radio-button>
          <mat-radio-button value="static">Static</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
}
