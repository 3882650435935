import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { OrderProduct } from 'src/@hop/models/order-product.model';
import { OrderModel } from 'src/@hop/models/order.model';
import { LayoutService } from 'src/@hop/services';
import { OrdersService } from 'src/@hop/services/orders.service';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PublicProfileService } from '../../services/public-profile.service';

@UntilDestroy()
@Component({
  selector: 'hop-basket-view',
  templateUrl: './basket-view.component.html',
  styleUrls: ['./basket-view.component.scss']
})
export class BasketViewComponent implements OnInit {
  order: OrderModel;
  products: OrderProduct[];
  username: string;
  constructor(
    public layoutService: LayoutService,
    private router: Router,
    private ordersService: OrdersService,
    private publicUserService: PublicProfileService
  ) {
    router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
      this.layoutService.closeBasket();
    });
    this.publicUserService.userProfile$.pipe(untilDestroyed(this)).subscribe((userProfile) => {
      this.username = userProfile?.user?.username;
    });
  }

  ngOnInit(): void {
    this.ordersService.cart$.subscribe((order) => {
      this.order = order;
      this.products = order?.orderProducts;
    });
  }

  async getBasket() {
    this.ordersService.fromWebsocketCart$.next(this.ordersService.fromWebsocketCart$.value);
  }

  goToProductsAndCloseBasket() {
    this.layoutService.closeBasket();
    this.router.navigate([`@${this.username}/s`]);
  }
}
