<div class="">
  <h1 mat-dialog-title class="text-lg">{{"_reviews.edit-review" | translate }}</h1>

  <div mat-dialog-content>
    <div class="flex flex-col space-y-5">
      <div [formGroup]="form">
        <div class="flex flex-col">
          <div class="flex flex-col space-y-6">
            <p class="font-bold">{{"_reviews.your-star-rating" | translate }}</p>
            <app-stars-rating formControlName="rating"></app-stars-rating>
          </div>
          <div class="flex flex-col w-full">
            <p class="text-lg font-bold">{{"_reviews.your-review" | translate }}</p>
            <mat-form-field class="">
              <textarea
                rows="5"
                placeholder='{{"_reviews._placeholder.body" | translate }}'
                data-testid="textarea-reason-cancel"
                matInput
                formControlName="body"
              >
              </textarea>
              <mat-error><hop-mat-form-error control="body"></hop-mat-form-error></mat-error>
            </mat-form-field>
            <div class="self-end flex space-x-3">
              <button (click)="onClose()" type="button" class="px-5" style="height: 50px" mat-stroked-button color="primary">{{"_general.cancel" | translate }}</button>

              <button
                [disabled]="form?.invalid"
                type="button"
                class="px-5"
                style="height: 50px"
                (click)="submitReview()"
                mat-raised-button
                color="primary"
              >
                {{"_reviews.submit-review" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
