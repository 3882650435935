<div class="card-generic space-y-4">
  <div class="w-full flex flex-col">
    <div class="flex justify-between">
      <div class="self-start flex flex-row space-x-3">
        <img class="rounded-full h-16 w-16 justify-center m-auto object-contain"
          [src]="review?.user?.imageFile?.url || '../../../../../assets/img/profile-image.png'" />
        <div class="flex flex-col self-center">
          <a [hopRouterLink]="'/@'+review?.user?.username" class="username text-sm self-start">
            &#64;{{ review?.user?.username }}
          </a>
          <p class="">{{ timeUtilsService.formatDate(review?._dateCreated, translateService) }}</p>
        </div>
      </div>
      <div class="flex space-x-3">
        @if (review?.user?.username === user?.username) {
          <button (click)="openEditDialogReview()" type="button"
            mat-icon-button><mat-icon>edit</mat-icon></button>
          }
          @if (review?.user?.username === user?.username) {
            <button (click)="deleteReview(review)" type="button"
              mat-icon-button><mat-icon>delete</mat-icon></button>
            }

          </div>
        </div>

        <div class="w-full flex flex-col space-y-3">
          <app-stars-rating-view [selectedValueView]="review?.rating"></app-stars-rating-view>
          <div readMore="300px">
            <p style="overflow-wrap: anywhere" class="whitespace-pre-line">
              {{ review?.body }}
              @if (review?.isEdited) {
                <span class="text-gray text-sm">(edited)</span>
              }
            </p>
          </div>
          @if (showProduct) {
            <p>
              {{ '_general.service' | translate }}:
              <a class="underline text-primary" [routerLink]="review?.product._uri">{{ review.product.name }}</a>
            </p>
          }
          @if (user) {
            <button class="w-min self-end" (click)="replyToReviewToggle()" type="button" mat-button>
              <span><mat-icon>comment</mat-icon></span>
            {{'_general.reply' | translate}}</button>
          }
          <div class="md:ml-5">
            @if (replyToReview) {
              <div>
                <hr class="my-4">
                <hop-write-comment #writeComment (submittedComment)="addReplyToReview($event)" [reviewUuid]="review?.uuid"
                (onHideWriteComment)="replyToReview = false" [review]="review"></hop-write-comment>
              </div>
            }
            <div>
              <hop-all-comments [reviewUuid]="review?.uuid" [comments]="review?.comments"></hop-all-comments>
            </div>
          </div>
        </div>
      </div>
    </div>