import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BaseObjectModel } from '../../models';
import { Router, RouterLink } from '@angular/router';
import { getDiff } from 'recursive-diff';
import { AppRouterLinkDirective } from '../router-link/router-link.directive';

@Component({
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, AppRouterLinkDirective],
  selector: 'hop-link',
  template: `
    @if (url) {
      <a
        matTooltipPosition="above"
        matTooltip="View {{ type }} Details"
        class="cursor-pointer"
        [hopRouterLink]="url"
        [target]="blank ? '_blank' : '_self'"
        >
        <mat-icon svgIcon="mat:open_in_new" style="width: 1rem; height: 1rem"></mat-icon>
      </a>
    }
    `
})
export class LinkComponent implements OnChanges {
  @Input() object: Partial<BaseObjectModel>;
  @Input() blank = false;
  @Input() type: string = 'Service';
  url: string = '';

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    const diff = getDiff(changes.object.previousValue, changes.object.currentValue);
    if (diff.length && this.object?._uri) {
      this.url = this.router.createUrlTree([this.object?._uri]).toString();
    }
  }

  /*goToProductDetails(object: any) {
    if (object?.uuid) {
      const url = this.router.createUrlTree([object?._uri]).toString();
      window.open(url, this.blank ? '_blank' : '_self');
    }
  }*/
}
