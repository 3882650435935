<div class="px-1 menu-button">
  @if (!showName) {
    <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
      <hop-icon-flag class="w-6" [code]="(language$|async).code"></hop-icon-flag>
    </button>
  }
  @if (showName) {
    <button [matMenuTriggerFor]="languageMenu" mat-flat-button type="button">
      <hop-icon-flag class="pr-2" [code]="(language$|async).code"></hop-icon-flag> <span>{{(language$|async).name}}</span>
    </button>
  }
</div>

<mat-menu #languageMenu="matMenu" xPosition="before" yPosition="below">
  @if (!manyAdditionalLangs?.length) {
    <div>
      @for (language of selectAvailableLanguages; track language) {
        <button mat-menu-item class="flex items-center" (click)="selectLang(language)"
          [hidden]='(language$|async).code === language.code'>
          <hop-icon-flag class="pr-2" [code]="language?.code"></hop-icon-flag>
          <span>{{language.name}}</span>
        </button>
      }
    </div>
  }
  @if (manyAdditionalLangs?.length) {
    <div>
      @for (language of manyAdditionalLangs; track language) {
        <button mat-menu-item class="flex items-center" (click)="selectLang(language)"
          [hidden]='(language$|async).code === language.code'>
          <hop-icon-flag class="pr-2" [code]="language?.code"></hop-icon-flag>
          <span>{{language.name}}</span>
        </button>
      }
    </div>
  }
</mat-menu>