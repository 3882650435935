import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCommentModule } from '../full-comment/full-comment.module';
import { AllCommentsComponent } from './all-comments.component';

@NgModule({
  declarations: [AllCommentsComponent],
  imports: [CommonModule, FullCommentModule],
  exports: [AllCommentsComponent]
})
export class AllCommentsModule { }
