import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PublicFooterComponent } from './public-footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { LanguageMenuModule } from '../../components/language-menu/language-menu.module';
import { ButtonThemeDarkModule } from '../../components/button-theme-dark/button-theme-dark.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SocialButtonsComponent } from '../../components/social-buttons/social-buttons.component';
import { ButtonHelpChatwoot } from "../../components/button-help-chatwoot/button-help-chatwoot.component";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    LanguageMenuModule,
    ButtonThemeDarkModule,
    RouterModule,
    TranslateModule,
    SocialButtonsComponent,
    ButtonHelpChatwoot
  ],
  declarations: [PublicFooterComponent],
  exports: [PublicFooterComponent]
})
export class PublicFooterModule { }
