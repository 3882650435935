import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { interval, Subject, Subscription } from 'rxjs';
import { AuthenticationService } from '../../services';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';


/** Data structure for holding telephone number. */
export class UserEmailCode {
  constructor(public emailCode: string) {}
}

/** Custom `MatFormFieldControl` for user code email. */
@Component({
  selector: 'hop-user-email-code',
  template: `
    <div
      role="group"
      class="email-code-input-container flex relative"
      [formGroup]="localForm"
      [attr.aria-labelledby]="_formField.getLabelId()"
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      >
      <input
        class="email-code-input-element"
        formControlName="emailCode"
        size="6"
        maxLength="6"
        aria-label="Email code"
        [hidden]="!emailCodeSent"
        (input)="_handleInput(localForm.controls.emailCode)"
        #emailCode
        />
      <button
        mat-flat-button
        color="accent"
        data-testid="hop-user-email-code-resend-button"
        class="email-code-resend-button absolute -right-2 -top-3"
        type="button"
        (click)="sendEmailCode()"
        [disabled]="!emailCodeAllowSend"
        aria-label="Resend email code"
        >
        <span class="email-code-resend-button-text">{{ '_general.' + (emailCodeSent ? 'resend' : 'send') | translate }}</span>
        @if (!emailCodeAllowSend) {
          <span class="email-code-resend-button-timer"> {{ resendTimer }}</span>
        }
      </button>
    </div>
    `,
  styles: [
    `
      .email-code-input-element {
        border: none;
        background: none;
        padding: 0;
        outline: none;
        font: inherit;
        color: currentcolor;
      }
    `
  ],
  providers: [{ provide: MatFormFieldControl, useExisting: UserEmailCodeComponent }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id'
  },
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, MatButtonModule]
})
export class UserEmailCodeComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
  static nextId = 0;
  @ViewChild('emailCode') emailCodeInput: HTMLInputElement;

  @Input() emailToSend: string;

  localForm: FormGroup<{
    emailCode: FormControl<string | null>;
  }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'email-code-input';
  id = `email-code-input-${UserEmailCodeComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: { emailCode }
    } = this.localForm;

    return !emailCode;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.localForm.disable() : this.localForm.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    if (this.localForm.valid) {
      const {
        value: { emailCode }
      } = this.localForm;
      return emailCode!;
    }
    return null;
  }
  set value(emailCode: string | null) {
    this.localForm.setValue({ emailCode });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return (this.touched && this.localForm.invalid) || (this.ngControl.invalid && this.ngControl.touched);
  }

  manualStateCheckSubscription: Subscription;

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private authService: AuthenticationService
  ) {
    this.manualStateCheckSubscription = interval(1000).subscribe((data) => {
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      setTimeout(() => {
        if (this.ngControl.statusChanges) {
          this.ngControl.statusChanges.subscribe((data) => {
            this.stateChanges.next();
          });
        }
      }, 1000);
    }

    this.localForm = formBuilder.group({
      emailCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.manualStateCheckSubscription.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    this.sendEmailForTheFirstTime();
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  /*autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }*/

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.email-code-input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.sendEmailForTheFirstTime();
    this._focusMonitor.focusVia(this.emailCodeInput, 'program');
  }

  writeValue(emailCode: string | null): void {
    this.value = emailCode;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl): void {
    //this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  emailCodeSent = false;
  emailCodeAllowSend = true;
  lastEmailCodeSent = '';
  resendTimer: string;
  sendEmailCode() {
    if (!this.emailToSend) {
      return;
    }
    this.authService.sendEmailCodeNewClient(this.emailToSend, this.checkIfEmailExists).subscribe((data) => {
      console.log(data);
    });
    this.emailCodeSent = true;
    this.emailCodeAllowSend = false;
    this.lastEmailCodeSent = '';
    this.localForm.controls.emailCode.setValue('');
    setTimeout(() => {
      this.localForm.controls.emailCode.setErrors(null);
      this.ngControl.control?.setErrors(null);
      this.ngControl.control?.markAsUntouched();
      this.localForm.controls.emailCode.markAsUntouched();
    }, 100);
    this.resendTimerFunction(60);
  }
  // a resendTimer function that takes a number of seconds and once every second decrements the number and sets it to resendTimer variable
  // once it reaches 0 it allows the user to resend the email code
  @Input() checkIfEmailExists = false;
  resendTimerFunction(seconds = 30) {
    this.resendTimer = seconds.toString();
    const interval = setInterval(() => {
      seconds--;
      this.resendTimer = seconds.toString();
      if (seconds === 0) {
        this.emailCodeAllowSend = true;
        clearInterval(interval);
      }
    }, 1000);
  }

  private sendEmailForTheFirstTime() {
    if (!this.emailCodeSent) {
      this.sendEmailCode();
      setTimeout(() => {
        this._focusMonitor.focusVia(this.emailCodeInput, 'program');
      }, 100);
    }
  }
}
