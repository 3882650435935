import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hop-mat-form-error',
  template: `
    @if (isNotValid) {
      <div class="mat-mdc-form-field-error-wrapper">
        <!--<mat-error *ngIf="formControl?.hasError('required')">Required</mat-error>
        <mat-error *ngIf="formControl?.hasError('email')">Email address must be valid</mat-error>-->
        @if (formControl?.hasError('apiError')) {
          <mat-error>{{ formControl?.getError('apiError') }}</mat-error>
        }
        @if (otherError && !formControl?.hasError('apiError')) {
          <mat-error>{{ otherError }}</mat-error>
        }
      </div>
    }
    `,
  styles: [``]
})
export class MatFormErrorsComponent {
  @Input() control: string;

  @Input() visible: any;

  constructor(private controlContainer: ControlContainer, private translateService: TranslateService) {}

  get form(): FormGroup {
    return this.controlContainer?.control as FormGroup;
  }

  get formControl(): AbstractControl {
    return this.form?.get(this.control) as AbstractControl;
  }

  get isNotValid() {
    return this.formControl?.invalid && (this.formControl?.touched || this.formControl?.dirty);
  }

  get otherError() {
    if (this.formControl?.errors) {
      const errorsArray = Object.keys(this.formControl?.errors);
      const error = this.formControl?.getError(errorsArray[0]);
      const interpolation = {
        property: this.translateService.instant(this.control)
      };
      switch (errorsArray[0]) {
        case 'minlength':
          interpolation['constraints.0'] = error?.requiredLength;
          break;
        case 'min':
          interpolation['constraints.0'] = error?.min;
          break;
        case 'max':
          interpolation['constraints.0'] = error?.max;
          break;
        default:
          // interpolation['constraints.0']= error?.requiredLength;
          break;
      }
      return this.translateService.instant('_validation.' + errorsArray[0], interpolation);
    }
    return false;
  }
}
