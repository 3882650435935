// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { FeaturesModel } from '../@hop/models';

const mainUrl = 'https://www-adu.koschedule.com';
const sentryUrl = 'https://dff7da7e803cf8ac32690d66f1e7e0fd@s.cosmoslevel.com/17';
const isActivatedSentry = true;

export const environment: any = {
  name: window.location.host.replace(/www./, ''),
  production: false,
  apiUrl: mainUrl + '/api',
  apiUploadUrl: mainUrl + '/api',
  apiSocketUrl: mainUrl,
  sentryUrl,
  mainUrl,
  isActivatedSentry,
  features: new FeaturesModel({
    isDebug: { value: true, payload: 'true' },
    isStripe: { value: true, payload: 'false' },
    isSearchHomePage: { value: false, payload: 'false' },
    isPhone: { value: true, payload: 'true' },
    isAccountWizard: { value: true, payload: 'true' }
  }),
  orProjectKey: 'EbI4BbbbOcdGyMktWcdf',
  orIngestPoint: 'https://or.cosmoslevel.com/ingest',
  isOrActivated: false,
  twitterId: '@koschedule',
  defaultLangCode: 'en',
  appConfig: {}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
