import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { OfferAppointmentService } from 'src/@hop/services/offerAppointment.service';
import { DeleteDialogComponent } from '../../availability-component/delete-dialog/delete-dialog.component';
import { Router } from '@angular/router';
import { UserState } from 'src/@hop/state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslateService } from '@ngx-translate/core';
import { TimeUtilsService } from '../../../../../@hop/services/timeUtils.service';

@UntilDestroy()
@Component({
  selector: 'hop-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.scss']
})
export class CardDashboardComponent implements OnInit {
  @Input() offerAppointment;
  @Output() offerAppointmentDeleted = new EventEmitter();

  constructor(
    private snackbar: MatSnackBar,
    private offerAppointmentService: OfferAppointmentService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public router: Router,
    public translateService: TranslateService,
    public timeUtilsService: TimeUtilsService,
    @Inject('environment') public environment
  ) {
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
  }

  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  user: UserModel;
  openSnackbarCopiedLink(linkType: 'public' | 'private') {
    this.snackbar.open('Copied offer link to clipboard', '', {
      duration: 3000
    });
    // this.snackbar.open(linkType === 'public' ? 'Copied custom link to clipboard' : 'Copied private link to clipboard', '', {
    //   duration: 3000
    // });
  }
  ngOnInit(): void {}

  checkIfOfferAppointmentToShowOnProfile(property = true) {
    return this.offerAppointment.showOnProfile === property;
  }

  editOfferAppointment(offerAppointment) {
    this.router.navigate([`/offer/edit/${offerAppointment.uuid}`]);
  }

  deleteOfferAppointment(offerAppointment) {
    this.dialog
      .open(DeleteDialogComponent, {
        data: {
          name: offerAppointment?.name,
          content: 'This action cannot be undone. Are you sure you want to delete this offerAppointment?'
        },
        panelClass: 'dialog-open-availability'
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value?.confirmDelete === true) {
          this.offerAppointmentService.deleteOfferAppointment(offerAppointment.uuid).subscribe({
            next: (result) => {
              this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.offer_deleted'), {
                timeOut: 3000,
                progressBar: true
              });
              this.offerAppointmentDeleted.next(null);
            },
            error: (error) => {
              if (error.error) {
              }
            }
          });
        }
      });
  }
}
