import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewMoreDirective } from './view-more.directive';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [ViewMoreDirective],
  exports: [ViewMoreDirective]
})
export class ViewMoreModule { }
