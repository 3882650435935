import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { AuthenticationService, FormUtilsService, UserStatus } from '../../../../@hop/services';
import { CachingService } from '../../../../@hop/services/caching.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ValueAccessorDirective } from '../../../../@hop/_helpers/value-accessor.directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormErrorsModule } from '../../../../@hop/components/mat-form-errors/mat-form-errors.module';

import { LoginEmailComponent } from './login/login-email/login-email.component';
import { RegisterEmailComponent } from './register/register-email/register-email.component';
import { LoginEmailCodeComponent } from './login/login-email-code/login-email-code.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormErrorsModule,
    RegisterEmailComponent,
    LoginEmailComponent,
    LoginEmailCodeComponent
],
  selector: 'hop-fast-sign-in-email',
  template: `
    @if (!emailStatus || emailStatus === 'unavailable') {
      <div [formGroup]="emailForm" class="flex flex-col space-y-1">
        <mat-form-field appearance="fill">
          <mat-label>{{ '_label.email' | translate }}</mat-label>
          <input
            name="email"
            (blur)="checkEmail()"
            (keydown.enter)="checkEmail()"
            [placeholder]="'_placeholder.email' | translate"
            formControlName="email"
            matInput
            #emailStatusControl
            />
          <mat-error>
            <hop-mat-form-error control="email"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
        <p data-testid="hop-fast-sign-in-global-button-forward" class="self-end cursor-pointer"><mat-icon>arrow_forward</mat-icon></p>
      </div>
    }
    @if (emailStatus === 'available') {
      <div [formGroup]="registerForm" class="flex flex-col space-y-4">
        <hop-register-email
          #registerEmailRef
          [email]="emailForm.controls.email.value"
          (emailClicked)="resetEmailStatus()"
          [isProfessional]="isProfessional"
        ></hop-register-email>
      </div>
    }
    @if (loginMethod === 'password') {
      <div [formGroup]="loginForm" class="flex flex-col space-y-4">
        <hop-login-email
          #loginEmailRef
          [email]="emailForm.controls.email.value"
          [navigateToDashboard]="false"
          (emailClicked)="resetEmailStatus()"
          [showUseCode]="true"
          (useCodeEvent)="useCode()"
        ></hop-login-email>
      </div>
    }
    @if (loginMethod === 'code') {
      <div [formGroup]="loginForm" class="flex flex-col space-y-4">
        <hop-login-email-code
          #loginEmailCodeRef
          [email]="emailForm.controls.email.value"
          [navigateToDashboard]="false"
          (emailClicked)="resetEmailStatus()"
        ></hop-login-email-code>
      </div>
    }
    `,
  //styleUrls: ['./user-fast-login.component.scss'],
  hostDirectives: [ValueAccessorDirective]
})
export class FastSignInEmailComponent implements OnInit {
  public emailStatus: 'available' | 'unavailable' | 'exists' | 'exists_' | undefined;
  @ViewChildren(LoginEmailComponent) loginEmailRef: QueryList<LoginEmailComponent>;
  @ViewChildren(LoginEmailCodeComponent) loginEmailCodeRef: QueryList<LoginEmailCodeComponent>;
  @ViewChildren(RegisterEmailComponent) registerEmailRef: QueryList<RegisterEmailComponent>;
  @Input() readOnly = false;
  @Input() isProfessional = false;
  protected loginMethod: 'password' | 'code' = null;
  protected isPasswordAvailable = false;
  constructor(
    private formUtilsService: FormUtilsService,
    private authService: AuthenticationService,
    private cachingService: CachingService
  ) {}
  @ViewChild('emailStatusControl') emailStatusControl?: ElementRef;
  emailForm: any;
  registerForm: any;
  loginForm: any;
  loginEmailCodeForm: any;

  ngOnInit() {
    this.emailForm = this.formUtilsService.createFormFromObject({ email: '' });
    this.registerForm = this.formUtilsService.createFormFromObject({ email: '', firstName: '', lastName: '' });
    this.loginForm = this.formUtilsService.createFormFromObject({ email: '', password: '' });
    this.loginEmailCodeForm = this.formUtilsService.createFormFromObject({ email: '', emailCode: '' });
    this.emailForm.controls.email.setValidators([Validators.required, Validators.email]);
    this.registerForm.controls.email.setValidators([Validators.required, Validators.email]);
    this.registerForm.controls.firstName.setValidators([Validators.required, Validators.minLength(3)]);
    this.registerForm.controls.lastName.setValidators([Validators.required, Validators.minLength(3)]);
    /*this.emailForm.valueChanges.subscribe((data) => {
      const emailControl = this.emailForm.get('email');
      console.log(data, emailControl);
    });*/
  }

  async checkEmail() {
    const emailControl = this.emailForm.get('email');
    if (emailControl.valid) {
      this.cachingService
        .remember('user-status-' + emailControl.value, this.authService.emailStatus(emailControl.value), false, 0.3)
        .pipe(
          catchError((e) => {
            if (e?.error?.errors) {
              this.formUtilsService.setFormErrors(e.error, this.emailForm);
            }
            return throwError(e);
          })
        )
        .subscribe((data: UserStatus) => {
          if (data.success) {
            this.emailStatus = data.status;
            if (data.status === 'unavailable') {
              this.emailForm.controls.email.setErrors({ unavailable: true });
            }
            if (data.status === 'available') {
              this.registerForm.controls.email.setValue(this.emailForm.controls.email.value);
              setTimeout(() => {
                this.registerEmailRef.get(0).firstNameRef.nativeElement.focus();
              }, 100);
            }
            if (data.status === 'exists') {
              this.usePassword();
            }
            if (data.status === 'exists_') {
              this.useCode();
            }
          } else {
            this.emailForm.controls.email.setErrors({ 'contact-support': true });
          }
        });
    }
  }

  resetEmailStatus() {
    this.emailStatus = undefined;
    this.loginMethod = null;
    this.isPasswordAvailable = false;
    setTimeout(() => {
      this.emailStatusControl?.nativeElement?.focus();
    }, 50);
  }

  usePassword() {
    this.loginMethod = 'password';
    this.isPasswordAvailable = true;
    setTimeout(() => {
      this.loginForm.controls.email.setValue(this.emailForm.controls.email.value);
      setTimeout(() => {
        this.loginEmailRef.get(0).passwordRef.nativeElement.focus();
      }, 100);
    }, 100);
  }

  useCode() {
    this.loginMethod = 'code';
    setTimeout(() => {
      this.loginEmailCodeForm.controls.email.setValue(this.emailForm.controls.email.value);
      setTimeout(() => {
        this.loginEmailCodeRef.first.emailCodeRef.sendEmailCode();
        setTimeout(() => {
          const loginEmailCodeField = this.loginEmailCodeRef.get(0).emailCodeRef;
          loginEmailCodeField.onFocusIn(null);
        }, 5000);
      }, 1000);
    }, 100);
  }

  protected readonly environment = environment;
}
