import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../../@hop/services';
import { Select, Store } from '@ngxs/store';
import { UserLogout, UserState } from '../../@hop/state';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';

@Injectable({ providedIn: 'root' })
export class ProfileGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store
  ) {
    this.user$.subscribe((user) => {
      this.user = user;
    });
  }
  user: UserModel;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      !this.user?.username ||
      (!this.user?.isEmailVerified && !this.user?.isPhoneVerified) ||
      (this.user?.needsPassword && this.user?.isProfessional) ||
      ((!this.user?.email || !this.user?.isEmailVerified) && this.user?.isProfessional)
    ) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/onboard'], { queryParams: { returnUrl: state.url } });
      console.log('redirect to onboard');
      return false;
    }
    // logged in so return true
    return true;
  }
}
