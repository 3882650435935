import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { UserState } from 'src/@hop/state';
import { DialogCustomUrlComponent } from 'src/app/pages/pages/new-links_____/one-on-one/dialog-custom-url/dialog-custom-url.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormErrorsModule } from '../mat-form-errors/mat-form-errors.module';
import { FileUploadService } from 'src/@hop/services/fles-upload.service';
import { MatMenuModule } from '@angular/material/menu';
import { SafeHtmlPipe } from '../../pipes/safeHtml.pipe';
import { OrdersService } from '../../services/orders.service';
import { OrderModel } from '../../models/order.model';
import { Router } from '@angular/router';
import { ProviderModel } from '../../models/provider.model';
import { catchError } from 'rxjs/operators';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@UntilDestroy()
@Component({
  selector: 'hop-dialog-bank-transfer-payment',
  standalone: true,
  imports: [
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormErrorsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    MatProgressBarModule
],
  template: `
    <div class="">
      <div class="flex justify-between">
        <h1 mat-dialog-title class="text-lg">{{ '_orders.pay-with.bankPayment' | translate }}</h1>
        <div mat-dialog-actions>
          <button mat-icon-button mat-dialog-close (click)="onNoClick()">
            <mat-icon svgIcon="mat:clear"></mat-icon>
          </button>
        </div>
      </div>
      <div mat-dialog-content>
        <div class="flex flex-col space-y-5">
          <div class="flex flex-col space-y-2">
            <p class="text-lg font-semibold">{{ '_orders.payment-details' | translate }}</p>
            <p class="">{{ '_orders.send-order-with-bank-transfer' | translate }}</p>
            <p class="p-0 overflow-y-hidden" [innerHTML]="data?.provider?.body | safeHtmlPipe"></p>
          </div>
          <!--<div [formGroup]="form" class="w-full overflow-hidden flex flex-col space-mat-icon p-0 m-0" floatLabel="always">
            <div class="flex w-full justify-between">
              <mat-label class="flex items-center">
                <mat-icon svgIcon="mat:description"></mat-icon>
                {{ '_orders._help.comments' | translate }}
              </mat-label>
              <div [matMenuTriggerFor]="infoBody" class="self-center" mat-icon-button>
                <mat-icon svgIcon="mat:help_outline"></mat-icon>
              </div>
              <mat-menu #infoBody="matMenu" xPosition="before">
                <p>{{ '_orders._help.comments' | translate }}</p>
              </mat-menu>
            </div>

            <mat-form-field>
              <textarea matInput [placeholder]="'_general.comments' | translate" formControlName="comments"> </textarea>
            </mat-form-field>
          </div>-->
        </div>
      </div>
      <div class="mt-10" mat-dialog-actions align="end">
        <div class="flex flex-col space-y-5">
          <button
            data-testid="hop-dialog-bank-transfer-payment-button-send"
            class="w-full"
            color="primary"
            mat-raised-button
            (click)="sendBankPaymentDetails()"
          >
            {{ '_orders.send-order' | translate }}
          </button>
        </div>
      </div>
    </div>
  `
})
export class BankTransferDialogComponent implements OnInit {
  form: UntypedFormGroup;
  email;
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  isLoading = false; // add isLoading property

  constructor(
    public dialogRef: MatDialogRef<DialogCustomUrlComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { provider: ProviderModel; order: OrderModel },
    public uploadService: FileUploadService,
    private orderService: OrdersService,
    private router: Router
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  sendBankPaymentDetails() {
    ///this.dialogRef.close();
    if (this.data?.order?.uuid) {
      this.orderService.sendWithBankPayment(this.data.order.uuid, this.form.value).subscribe((order: OrderModel) => {
        if (order) {
          this.dialogRef.close();
          this.router.navigate([order._uriCode]);
        }
      });
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      comments: ['']
    });
  }

  submit() {}
  async onCreateFile(fileObj) {
    const formData: FormData = await new FormData();
    await formData?.append('file', fileObj);
    return formData;
  }
}
