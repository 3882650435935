<div class="">
  <h1 mat-dialog-title class="text-lg">{{ dialogTitle }}</h1>

  @if (!data?.name) {
    <div mat-dialog-content>
      <div class="flex flex-col space-y-5">
        <p class="self-center">{{'_calendars.calendar-is-primary-and-cant-be-deleted' | translate}}</p>
        <button class="w-full" color="primary" mat-stroked-button (click)="onClose()">{{'_general.cancel' | translate}}</button>
      </div>
    </div>
  }
  @if (data?.name) {
    <div mat-dialog-content>
      <div class="flex flex-col space-y-5">
        <p class="self-center">
          {{ data?.content }}
        </p>
        <div mat-dialog-actions align="end">
          <button color="primary" mat-stroked-button (click)="onClose()">{{'_general.cancel' | translate}}</button>
          <button data-testid="button-dialog-delete" color="primary" mat-raised-button (click)="onConfirmDelete()">{{'_general.delete' | translate}}</button>
        </div>
      </div>
    </div>
  }
</div>
