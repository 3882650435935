import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { debounceTime, tap } from 'rxjs/operators';
import { UserAuth } from 'src/@hop/models/user-auth.model';
import { UserEdit, UserGet, UserLogin, UserLogout, UserUpdate } from '../actions/user.action';
import { UserModel } from '../../models';
import { AuthenticationService, UserService } from '../../services';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { LanguagesSet } from '../actions/app.action';
import { CachingService } from '../../services/caching.service';
import { SubscriptionWebsocketResponse, WebsocketService } from 'src/@hop/services/websocket.service';
import { DialogProfileComponent } from 'src/@hop/components/dialog-profile/dialog-profile.component';
import { DialogAcceptTermsComponent } from 'src/@hop/components/accept-terms/dialog-accept-terms.component';
import { MatDialog } from '@angular/material/dialog';

export class UserStateModel {
  user: UserModel;
}

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    user: null
  }
})
@Injectable()
export class UserState implements NgxsOnInit {
  ngxsOnInit(ctx: StateContext<UserStateModel>) {
    console.log('State initialized, now getting user');

    this.authenticationService.user$.subscribe((user) => {
      this.store.dispatch(new UserUpdate(user));
      //if on home page redirect to dashboard
      /*if (user && window.location.pathname === '/') {
        window.location.href = '/dashboard';
      }*/

      if (user) {
        setTimeout(() => {
          this.store.dispatch(new UserGet());
        });

      }
    });
  }
  fromWebsocketUser$: BehaviorSubject<SubscriptionWebsocketResponse> = new BehaviorSubject<SubscriptionWebsocketResponse>(null);

  constructor(
    private userService: UserService,
    private store: Store,
    private authenticationService: AuthenticationService,
    private cachingService: CachingService,
    private webSocketService: WebsocketService,
    private dialog: MatDialog
  ) {
    combineLatest([this.authenticationService.user$, this.fromWebsocketUser$]).subscribe(([user, response]) => {
      console.log('user$', response);
      if (response) {
        this.store.dispatch(new UserGet());
      }
      if (!response && user) {
        setTimeout(() => {
          this.webSocketService.addUuidSubscription(user.uuid, this.fromWebsocketUser$, this);
        }, 3000)
      }
    });
  }

  @Selector()
  static selectUser(state: UserStateModel) {
    return state.user;
  }

  @Action(UserGet)
  userGet(ctx: StateContext<UserStateModel>) {
    return this.cachingService.remember(
      'user-profile',
      this.userService.getUser().pipe(
        tap((returnData) => {
          const state = ctx.getState();
          ctx.setState({
            ...state,
            user: { ...returnData } //here the data coming from the API will get assigned to the users variable inside the appstate
          });
          if (returnData.manyAdditionalLangs?.length) {
            this.store.dispatch(new LanguagesSet(returnData.manyAdditionalLangs));
          }
        })
      ),
      false,
      (1 / 60) * 4
    );
  }
  @Action(UserUpdate)
  userUpdate(ctx: StateContext<UserStateModel>, data: { user: UserModel }) {
    const state = ctx.getState();
    // lang is not coming from cookie, so we will extend existing user in this case, so we don't emit a user with missing values
    if (!data.user) {
      ctx.setState({
        ...state,
        user: null
      });
    } else if (!data.user.lang) {
      ctx.setState({
        ...state,
        user: { ...state.user, ...data.user }
      });
    } else {
      ctx.setState({
        ...state,
        user: data.user
      });
    }
  }

  @Action(UserLogin)
  userLogin(ctx: StateContext<UserStateModel>, user: UserAuth) {
    return this.authenticationService.login(user.email, user.password, user.rememberMe);
  }

  @Action(UserLogout)
  userLogout(ctx: StateContext<UserStateModel>, user: UserAuth) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: null
    });
    return this.authenticationService.logout(true);
  }

  @Action(UserEdit)
  userEdit(ctx: StateContext<UserStateModel>, data: any): Observable<any> {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user: { ...data.user } //here the data coming from the API will get assigned to the users variable inside the appstate
    });
    if (data.user.manyAdditionalLangs?.length) {
      this.store.dispatch(new LanguagesSet(data.user.manyAdditionalLangs));
    }
    return of(state);
  }
}
