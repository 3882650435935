import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FeatureSet } from 'src/@hop/state/actions/features.action';
import { FeaturesModel } from 'src/@hop/models/featuresModel';
import { FeaturesState } from 'src/@hop/state/states/features-state.service';
import { AppState } from '../../../app/state/states/app-state.service';
import { ApiNameSet } from '../../state';
import { Meta } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { LayoutService, UserService } from '../../services';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'hop-complete-account',
  templateUrl: './complete-account.component.html',
  styleUrls: ['./complete-account.component.scss']
})
export class CompleteAccountComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;

  constructor(public layoutService: LayoutService) { }
  steps = [
    {
      "title": "Create your first Offer",
      "body": "<p>Go to <a href='/dashboard'>dashboard</a>, click the plus button and create a new offer</p>",
      "videoUrl": "https://www.youtube.com/embed/5QspYDWXsek",
      "helpUrl": "https://www.proo.ro/help/create-your-first-offer",
      "dateCompleted": null,
      "dateSkipped": "2021-09-01"
    },
    {
      "title": "Create your first Product",
      "body": "<p>Go to dashboard, click the plus button and create a new product</p>",
      "videoUrl": "https://www.youtube.com/embed/5QspYDWXsek",
      "helpUrl": "https://www.proo.ro/help/create-your-first-offer",
      "dateCompleted": '2021-09-01',
      "dateSkipped": null
    },
    {
      "title": "Add your first product to an offer",
      "body": "<p>Go to dashboard, click the plus button and create a new offer</p>",
      "videoUrl": "https://www.youtube.com/embed/5QspYDWXsek",
      "helpUrl": "https://www.proo.ro/help/create-your-first-offer",
      "dateCompleted": null,
      "dateSkipped": null
    }
  ]

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    let activeStepIndex = this.steps.findIndex(step => !step.dateCompleted && !step.dateSkipped);
    this.stepper['_updateSelectedItemIndex'](activeStepIndex);
  }

  getActiveStep() {
    let activeStepIndex = this.steps.findIndex(step => !step.dateCompleted && !step.dateSkipped);
    this.stepper.selectedIndex = activeStepIndex;
  }

  skipStep() {
    if (this.stepper.selectedIndex === this.steps.length - 1) {
      this.stepper['_updateSelectedItemIndex'](-1);
    }
  }


}
