
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatFormErrorsModule } from 'src/@hop/components/mat-form-errors/mat-form-errors.module';
import { FormUtilsService } from 'src/@hop/services';
import { CommentsService } from 'src/@hop/services/comments.service';

@Component({
  selector: 'hop-edit-comment-component',
  template: `
  
  <div class="">
  <h1 mat-dialog-title class="text-lg">{{"_comments.edit-comment" | translate }}</h1>

  <div mat-dialog-content>
    <div class="flex flex-col space-y-5">
      <div [formGroup]="form">
        <div class="flex flex-col">
          <div class="flex flex-col w-full">
            <p class="text-lg font-bold">{{"_comments.your-comment" | translate }}</p>
            <mat-form-field class="">
              <textarea
                rows="5"
                placeholder='{{"_comments._placeholder.body" | translate }}'
                data-testid="textarea-reason-cancel"
                matInput
                formControlName="body"
              >
              </textarea>
              <mat-error><hop-mat-form-error control="body"></hop-mat-form-error></mat-error>
            </mat-form-field>
            <div class="self-end flex space-x-3">
              <button (click)="onClose()" type="button" class="px-5" style="height: 50px" mat-stroked-button color="primary">{{"_general.cancel" | translate }}</button>

              <button
                [disabled]="form?.invalid"
                type="button"
                class="px-5"
                style="height: 50px"
                (click)="submitComment()"
                mat-raised-button
                color="primary"
              >
                {{"_comments.submit-comment" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`,

  standalone: true,
  imports: [MatButtonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatFormErrorsModule, MatDialogModule, TranslateModule]
})
export class EditCommentComponent implements OnInit {
  isWriteComment = false;
  form;

  isEditMode = false;
  constructor(
    private fb: UntypedFormBuilder,
    private commentsService: CommentsService,
    private toastr: ToastrService,

    public dialogRef: MatDialogRef<EditCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private formUtilsService: FormUtilsService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      body: [this.data?.body, Validators.required]
    });
  }

  async submitComment() {
    this.commentsService.editComment(this.form?.value, this.data?.uuid).subscribe({
      next: (result) => {
        this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.comment_edited'), {
          timeOut: 3000,
          progressBar: true
        });
        this.dialogRef.close({ result });
      },
      error: (error) => {
        if (error.error) {
          this.formUtilsService.setFormErrors(error.error, this.form);

        }
      }
    });

  }

  dialogTitle: string;

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirmDelete() {
    this.dialogRef.close({ confirmDelete: true });
  }
}
