import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import OpenReplay from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { environment } from '../../environments/environment';
import { UserModel } from '../../@hop/models';
import { Actions, Select, Store } from '@ngxs/store';
import { FeatureSet, UserState } from '../../@hop/state';
import { debounceTime, distinctUntilChanged, fromEvent, Observable, Subject } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { ChatWootService } from './chatwoot.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFeatureFlag } from '@openreplay/tracker/dist/lib/main/modules/featureFlags';

type ReqRespType = {
  request: HttpRequest<any>;
  response: HttpResponse<any>;
};
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ReplaySessionService {
  tracker: OpenReplay | null = null;
  @Select(UserState.selectUser) user$: Observable<UserModel>;

  emitFlags$: Subject<Array<any>> = new Subject();
  chatwootOnMessage$ = fromEvent<any>(window, 'chatwoot:on-message');
  constructor(
    private store: Store,
    private actions$: Actions,
    private chatWootService: ChatWootService
  ) {
    this.emitFlags$.pipe(debounceTime(300), untilDestroyed(this)).subscribe((flags) => {
      console.log('flags', flags);
      for (const flag of flags) {
        if (typeof flag.value !== 'string') {
          flag.value = true;
        }
        this.store.dispatch(new FeatureSet(flag.key, { value: flag.value, payload: flag.payload }));
      }
    });
    if (!environment.isOrActivated) {
      return;
    }
    /*projectID?: number;
    projectKey: string;
    sessionToken?: string;
    respectDoNotTrack?: boolean;
    autoResetOnWindowOpen?: boolean;
    resetTabOnWindowOpen?: boolean;
    network?: Partial<NetworkOptions>;
    mouse?: Partial<MouseHandlerOptions>;
    flags?: {
        onFlagsLoad?: (flags: IFeatureFlag[]) => void;
    };
    __DISABLE_SECURE_MODE?: boolean;*/
    this.tracker = new OpenReplay({
      projectKey: environment.orProjectKey,
      ingestPoint: environment.orIngestPoint,
      /*onStart: ({ sessionToken, sessionID }) => {
        Sentry.setTag('orSessionToken', sessionToken);
      },*/
      flags: {
        onFlagsLoad: (flags: IFeatureFlag[]) => {
          this.emitFlags$.next(flags);
        }
      }
    });
    //you can set up any other OR plugins here as well
    //const mobxObserver = this.tracker.use(trackerMobX());
    //mobxObserver.call();

    this.tracker.use(
      trackerAssist({
        confirmText: `You have an incoming call from ${environment.name}} Support. Do you want to answer?`
      })
    );

    this.actions$.pipe(distinctUntilChanged(({ action }) => action.constructor.name)).subscribe((action) => {
      if (action.action?.constructor.name) {
        //console.log(action.action.constructor.name);
        this.tracker.event('state-action', action.action.constructor.name);
      }
    });
    this.store.subscribe((store) => {
      //
      //this.tracker.event('store', store);
    });
    this.tracker.start().then((track) => {
      //const sessionToken = this.tracker.getSessionToken();
      //const sessionID = this.tracker.sessionID();
      if (this.tracker.getSessionID()) {
        const openReplayAssistUrl = this.tracker.getSessionURL().replace('session', 'assist');
        const openReplayReplayUrl = this.tracker.getSessionURL();
        Sentry.setExtra('openReplayAssistUrl', openReplayAssistUrl);
        Sentry.setExtra('openReplayReplayUrl', openReplayReplayUrl);
      }
      this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
        this.tracker.setUserID(user?.email);
        Sentry.setTag('email', user?.email);
        Sentry.setUser(user ? { email: user?.email } : null);
        try {
          const flags = this.tracker.reloadFlags();
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);
        }
      });
    });
    this.chatwootOnMessage$.pipe(untilDestroyed(this)).subscribe((e) => {
      // @ts-ignore
      //console.log('chatwoot:on-message', e.detail);
      if (this.tracker.getSessionID()) {
        console.log('set chatwoot or', this.tracker.getSessionID());
        // console.log(this.tracker.getSessionURL());
        const openReplayAssistUrl = this.tracker.getSessionURL().replace('session', 'assist');
        const openReplayReplayUrl = this.tracker.getSessionURL();
        // @ts-ignore
        window.$chatwoot.setConversationCustomAttributes({
          assist: openReplayAssistUrl,
          replay: openReplayReplayUrl
        });
        // we can use also the setCustomAttributes for the contact details
      }
    });
  }

  getType(entity) {
    var x = Object.prototype.toString.call(entity);
    return x.split(' ')[1].split(']')[0].toLowerCase();
  }

  sendEventToReplaySession(event: string, params: ReqRespType): void {
    const { request, response } = params;

    this.tracker?.event(event + '[request]', {
      method: request.method,
      url: request.url,
      params: request.params
    });
    this.tracker?.event(event + '[response]', {
      body: response.body,
      status: response.status,
      headers: response.headers
    });
  }

  trackEvent(event: string, payload?: any) {
    if (environment.isOrActivated) {
      try {
        this.tracker.event(event, payload);
      } catch (e) { }
    }
  }
  trackIssue(event: string, payload?: any) {
    if (environment.isOrActivated) {
      try {
        this.tracker.issue(event, payload);
      } catch (e) { }
    }
  }
}
