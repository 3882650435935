import { combineLatest, concat, defer, fromEvent, Observable, of, timer } from 'rxjs';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { distinctUntilChanged, flatMap, map, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

interface StatusElement {
  group: string;
  pageUri?: string;
  number?: number;
  element: ElementRef;
  name?: string;
}
/*
* let subscription = this.inViewService
      .elementInSight(
        new ElementRef(options._get('element', options)),
        options._get('intersectionOptions', { rootMargin: '0px 0px 900px 0px' }),
        options._get('delayOnlySeconds', 0)
      )
      .pipe(filter((visible) => !!visible));

 *
 * this.inViewService.elementInSight(this._elRef, {rootMargin: '0px 0px 900px 0px'}, 15).pipe(takeWhileAlive(this)).subscribe(visible => {
        if (visible) {
          this.visible = visible;
          this.renderer.removeStyle(this._elRef.nativeElement, 'min-height');
          this.renderer.removeStyle(this._elRef.nativeElement, 'min-width');
          this.inViewService.addImage(this.url);
          this.applyImg();
        }
      });
*
*
* */
@Injectable()
export class InViewService {
  private pageVisible$: Observable<boolean>;
  private isBot = this.isBotTest();
  private imagesLoaded: Array<string> = [];

  public addImage(url: string) {
    this.imagesLoaded.push(url);
  }

  public imageAlreadyLoaded(url: string): boolean {
    return !!this.imagesLoaded.find((image) => image === url);
  }

  constructor(@Inject(DOCUMENT) document: any) {
    this.pageVisible$ = concat(
      defer(() => of(!document.hidden)),
      fromEvent(document, 'visibilitychange').pipe(map((e) => !document.hidden))
    );
  }

  elementInSight(element: ElementRef, options = { rootMargin: '0px 0px 900px 0px' }, delayOnlySeconds = 0): Observable<boolean> {
    if (!element) {
      console.warn('no element specified');
      return of(null);
    }
    if (!('nativeElement' in element)) {
      console.error('elementInSight: element is not an ElementRef');
      return of(null);
    }
    let elementVisible$: Observable<boolean>;
    if (!('IntersectionObserver' in window)) {
      console.log('no intersection observer');
      elementVisible$ = of(true);
    } else {
      elementVisible$ = new Observable((observer) => {
        const intersectionObserver = new IntersectionObserver((entries) => {
          observer.next(entries);
        }, options);

        intersectionObserver.observe(element.nativeElement);

        return () => {
          intersectionObserver.disconnect();
        };
      }).pipe(
        flatMap((entries: any) => entries),
        map((entry: any) => entry.isIntersecting),
        distinctUntilChanged()
      );
    }

    const delayOnlySeconds$ = timer(0, delayOnlySeconds * 1000 + 1).pipe(
      take(2),
      map((value) => (value ? !!delayOnlySeconds : false))
    );

    const elementInSight$ = combineLatest(
      this.pageVisible$,
      elementVisible$,
      delayOnlySeconds$,
      (pageVisible, elementVisible, delayOnlySeconds) => pageVisible && (elementVisible || delayOnlySeconds || this.isBot)
    ).pipe(distinctUntilChanged());

    // @ts-ignore
    return elementInSight$;
  }

  isBotTest() {
    let BOT_REGEX =
      /bot|crawler|80legs|adsbot-google|008|abachobot|accoona-ai-agent|addsugarspiderbot|anyapexbot|arachmo|b-l-i-t-z-b-o-t|baiduspider|becomebot|beslistbot|billybobbot|bimbot|bingbot|blitzbot|boitho.com-dc|boitho.com-robot|btbot|catchbot|cerberian drtrs|charlotte|converacrawler|cosmos|covario ids|dataparksearch|diamondbot|discobot|dotbot|emeraldshield.com |webbot|esperanzabot|exabot|fast enterprise crawler|fast-webcrawler|fdse robot|findlinks|furlbot|fyberspider|g2crawler|gaisbot|galaxybot|geniebot|gigabot|girafabot|googlebot|googlebot-image|gurujibot|happyfunbot|hl_ftien_spider|holmes|htdig|iaskspider|ia_archiver|iccrawler|ichiro|igdespyder|irlbot|issuecrawler|jaxified bot|jyxobot|koepabot|l.webis|lapozzbot|larbin|ldspider|lexxebot|linguee bot|linkwalker|lmspider|lwp-trivial|mabontland|magpie-crawler|mediapartners-google|mj12bot|mnogosearch|mogimogi|mojeekbot|moreoverbot|morning paper|msnbot|msrbot|mvaclient|mxbot|netresearchserver|netseer crawler|newsgator|ng-search|nicebot|noxtrumbot|nusearch spider|nutchcvs|nymesis|obot|oegp|omgilibot|omniexplorer_bot|oozbot|orbiter|pagebiteshyperbot|peew|polybot|pompos|postpost|psbot|pycurl|pingdom.com_bot_version|qseero|radian6|rampybot|rufusbot|sandcrawler|sbider|scoutjet|scrubby|searchsight|seekbot|semanticdiscovery|sensis web crawler|seochat::bot|seznambot|shim-crawler|shopwiki|shoula robot|silk|sitebot|snappy|sogou spider|sosospider|speedy spider|sqworm|stackrambler|suggybot|surveybot|synoobot|teoma|terrawizbot|thesubot|thumbnail.cz robot|tineye|truwogps|turnitinbot|tweetedtimes bot|twengabot|updated|urlfilebot|vagabondo|voilabot|vortex|voyager|vyu2|webcollage|websquash.com|wf84|wofindeich robot|womlpefactory|xaldon_webspider|yacy|yahoo! slurp|yahoo! slurp china|yahooseeker|yahooseeker-testing|yandexbot|yandeximages|yasaklibot|yeti|yodaobot|yooglifetchagent|youdaobot|zao|zealbot|zspider|zyborg/i;
    return BOT_REGEX.test(navigator.userAgent);
  }
}
