
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, map, throwError, timeout } from 'rxjs';
import { WebsocketService } from '../../services/websocket.service';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'hop-check-internet-connection',
  template: ` @if (appOffline) {
  <div class="fixed text-center  top-0 z-50 w-full bg-yellow-200 bg-opacity-80">
    {{ '_general.app-is-reconnecting' | translate }}
  </div>
}`,
  standalone: true,
  imports: [TranslateModule]
})
export class CheckInternetConnectionComponent implements OnInit {
  appOffline = false;
  endpoint = `${this.environment.apiUrl}/lang`;
  constructor(
    @Inject('environment') private environment,
    private http: HttpClient,
    //private langService: LangService,
    public websocketService: WebsocketService
  ) {
    this.websocketService.wsOnline$.pipe(debounceTime(5000), untilDestroyed(this)).subscribe((data) => {
      if (data === false) {
        this.appOffline = true;
      }
    });
    this.websocketService.wsOnline$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data === true) {
        this.appOffline = false;
      }
    });
  }

  ngOnInit(): void {
    //this.appOffline = this.websocketService.wsOnline;
    /*setInterval(() => {
      this.getLangs().subscribe();
    }, 60000);*/
  }

  getLangs() {
    return this.http.get<any>(this.endpoint).pipe(
      timeout(10000),
      map((data) => {
        this.appOffline = false;
        return data;
      }),
      catchError((err) => {
        this.appOffline = true;
        return throwError(err);
      })
    );
  }
}
