import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PhoneInputComponent } from 'hop-calendar';
import { AddEmailComponent } from '../add-email/add-email.component';
@UntilDestroy()
@Component({
  selector: 'dialog-support-email',
  template: `
    <div class="">
  <div class="flex justify-between">
    <h1 mat-dialog-title class="text-lg">{{ '_general.support_email' | translate }}</h1>
    <div mat-dialog-actions>
      <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div>
  <div mat-dialog-content>
         <!-- support email text -->
          <div class="flex flex-col items-center">
            <p >{{ '_general.support_email_text' | translate :{value:environment?.name} }}</p>
          </div>
</div>
  </div>
  
`,
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslateModule, MatDialogModule, PhoneInputComponent,
    AddEmailComponent
  ]
})
export class DialogSupportEmailComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogSupportEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('environment') public environment: any

  ) {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }



}
