<!--<app-open-menu-user></app-open-menu-user>-->
<mat-sidenav-container class="example-container z-40">
  <ng-template #toolbarRef>
    <hop-toolbar [hasShadow]="toolbarShadowEnabled$ | async" [mobileQuery]="!(isMedium$ | async)" [user]="user$ | async"
    class="hop-toolbar"></hop-toolbar>
  </ng-template>
  <ng-template #sidenavRef>
    <hop-sidenav [collapsed]="sidenavCollapsed$ | async"></hop-sidenav>
  </ng-template>

  <ng-template #footerRef>
    @if (isFooterVisible$ | async) {
      <hop-public-footer class="hop-footer"></hop-public-footer>
    }
  </ng-template>


  <ng-template #quickpanelRef>
    <hop-quickpanel></hop-quickpanel>
  </ng-template>


  <!-- <mat-sidenav (blur)="cl()" #menuUser [fixedInViewport]="!(isDesktop$ | async)" class="menuUser" mode="over"
  position="end" (closed)="layoutService.closeMenuUser()">
  <hop-quickpanel></hop-quickpanel>
</mat-sidenav> -->
<ng-template #menuUserTemplate>
  <hop-menu-user></hop-menu-user>
</ng-template>

<!--<mat-sidenav #menuUser [fixedInViewport]="!(isDesktop$ | async)" class="menuUser fixed" mode="over" position="end"
(closed)="layoutService.closeMenuUser()">
<ng-container *ngTemplateOutlet="menuUserTemplate"></ng-container>
</mat-sidenav>-->


<!-- </mat-sidenav-container> -->

<hop-layout [footerRef]="footerRef" [quickpanelRef]="quickpanelRef" [sidenavRef]="sidenavRef"
  [toolbarRef]="toolbarRef">
</hop-layout>

<hop-config-panel-toggle *ifFeature="FeatureEnum.isConfig"
(openConfig)="configpanel.open()"></hop-config-panel-toggle>

<!-- CONFIGPANEL -->
<hop-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <hop-config-panel></hop-config-panel>
</hop-sidebar>
<!-- END CONFIGPANEL --></mat-sidenav-container>
<!-- basket -->
<hop-sidebar #basket [invisibleBackdrop]="true" position="right">
  <hop-basket-view></hop-basket-view>
</hop-sidebar>