@if (isLink(item) && !isFunction(item.route) && !item.disabled) {
  <a
    [class.hover:bg-hover]="!(isActive$ | async)(item)"
    [ngClass]="{ underline: (isActive$ | async)(item), 'navigation-color': !(isActive$ | async)(item) }"
    [routerLink]="item.route"
    [queryParams]="item.queryParams"
    class="navigation-item"
    [class]="item.class"
    matRipple
    >
    {{ item.label }}
  </a>
}

@if (isLink(item) && isFunction(item.route)) {
  <div
    (click)="item.route()"
    [class.hover:bg-hover]="!(isActive$ | async)(item)"
    class="navigation-item navigation-color"
    matRipple
    >
    {{ item.label }}
  </div>
}

@if ((isSubheading(item) && item.children?.length > 0) || isDropdown(item)) {
  <div
    [class.hover:bg-hover]="!(isActive$ | async)(item)"
    [matMenuTriggerFor]="menu"
    [ngClass]="{ underline: (isActive$ | async)(item), 'navigation-color': !(isActive$ | async)(item) }"
    class="navigation-item"
    matRipple
    >
    {{ item.label }}
  </div>
  <mat-menu #menu="matMenu" yPosition="below">
    @for (child of item.children; track child) {
      @if (isLink(child) && !isFunction(child.route) && !child.disabled) {
        <a
          [routerLink]="child.route"
          [queryParams]="child.queryParams"
          [ngClass]="{ 'text-primary': (isActive$ | async)(child) }"
          class="navigation-menu-item"
          mat-menu-item
          >
          <mat-icon [svgIcon]="child.icon" class="text-current"></mat-icon>
          <span>{{ child.label }}</span>
        </a>
      }
      @if (isLink(child) && isFunction(child.route)) {
        <div (click)="child.route()" class="navigation-menu-item" mat-menu-item>
          <mat-icon [svgIcon]="child.icon" class="text-current"></mat-icon>
          <span>{{ child.label }}</span>
        </div>
      }
      @if (isDropdown(child) && !child.disabled) {
        <button [matMenuTriggerFor]="level1" [ngClass]="{ 'text-primary': (isActive$ | async)(child) }" class="navigation-menu-item" mat-menu-item>
          <mat-icon [svgIcon]="child.icon" class="text-current"></mat-icon>
          <span>{{ child.label }}</span>
        </button>
        <mat-menu #level1="matMenu" yPosition="below">
          @for (item of child.children; track item) {
            <ng-container [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="linkTemplate"></ng-container>
            @if (isDropdown(item) && !item.disabled) {
              <button
                [matMenuTriggerFor]="level2"
                class="navigation-menu-item"
                [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
                mat-menu-item
                >
                {{ item.label }}
              </button>
              <mat-menu #level2="matMenu" yPosition="below">
                @for (child of item.children; track child) {
                  <ng-container [ngTemplateOutletContext]="{ item: child }" [ngTemplateOutlet]="linkTemplate"></ng-container>
                  @if (isDropdown(child) && !child.disabled) {
                    <button
                      [matMenuTriggerFor]="level3"
                      class="navigation-menu-item"
                      [ngClass]="{ 'text-primary': (isActive$ | async)(child) }"
                      mat-menu-item
                      >
                      {{ child.label }}
                    </button>
                    <mat-menu #level3="matMenu" yPosition="below">
                      @for (item of child.children; track item) {
                        <ng-container [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="linkTemplate"></ng-container>
                        @if (isDropdown(item) && !item.disabled) {
                          <button
                            [matMenuTriggerFor]="level4"
                            class="navigation-menu-item"
                            [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
                            mat-menu-item
                            >
                            {{ item.label }}
                          </button>
                          <mat-menu #level4="matMenu" yPosition="below">
                            @for (child of item.children; track child) {
                              <ng-container [ngTemplateOutletContext]="{ item: child }" [ngTemplateOutlet]="linkTemplate"></ng-container>
                              @if (isDropdown(child) && !child.disabled) {
                                <button
                                  [matMenuTriggerFor]="level5"
                                  class="navigation-menu-item"
                                  [ngClass]="{ 'text-primary': (isActive$ | async)(child) }"
                                  mat-menu-item
                                  >
                                  {{ child.label }}
                                </button>
                                <mat-menu #level5="matMenu" yPosition="below">
                                  @for (item of child.children; track item) {
                                    <ng-container [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="linkTemplate"></ng-container>
                                  }
                                </mat-menu>
                              }
                            }
                          </mat-menu>
                        }
                      }
                    </mat-menu>
                  }
                }
              </mat-menu>
            }
          }
        </mat-menu>
      }
    }
  </mat-menu>
  <ng-template #linkTemplate let-item="item">
    @if (isLink(item) && !isFunction(item.route) && !item.disabled) {
      <a
        [routerLink]="item.route"
        [queryParams]="item.queryParams"
        class="navigation-menu-item"
        [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
        mat-menu-item
        >{{ item.label }}</a
        >
      }
      @if (isLink(item) && isFunction(item.route) && !item.disabled) {
        <div
          (click)="item.route()"
          class="navigation-menu-item"
          [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
          mat-menu-item
          >
          {{ item.label }}
        </div>
      }
    </ng-template>
  }
