@if (steps?.length>0) {
  <div class="flex flex-col space-y-5 p-2 xs:p-6">
    <div class="flex justify-between">
      <h1 class="self-center">Complete Account Steps</h1>
      <button class="self-center" mat-icon-button (click)="layoutService.closeCompleteAccount()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-vertical-stepper #stepper>
      <ng-template matStepperIcon="edit" let-index="index">
        <mat-icon>check_circle</mat-icon>
      </ng-template>
      @for (step of steps; track step) {
        <mat-step [completed]="step?.dateCompleted !== null"
          [ngClass]="{'skipped-step': step?.dateSkipped, 'completed-step': step?.dateCompleted}" label="Step 1">
          <ng-template matStepLabel>
            <span [ngClass]="{'skipped': step?.dateSkipped, 'completed': step?.dateCompleted}">
              {{step?.title}}
            </span>
            @if (step?.dateSkipped) {
              <span style="color: gray;">(skipped)</span>
            }
            @if (step?.dateCompleted) {
              <span style="color: green;">(completed)</span>
            }
          </ng-template>
          <!-- Completed Icon -->
          <!-- <ng-template matStepperIcon="done">
          <mat-icon>check_circle</mat-icon>
        </ng-template> -->
        <!-- <ng-template *ngIf="step?.dateCompleted" matStepperIcon="number">
        <mat-icon>check_circle</mat-icon>
      </ng-template> -->
      <div class="flex flex-col space-y-5">
        <div class="flex space-x-2 justify-center">
          <p [innerHTML]="step?.body"></p>
          <a matTooltip="{{ '_general.read-more' | translate }}" matTooltipPosition="above" target="_blank"
            [href]="step?.helpUrl"><mat-icon>help_outline</mat-icon></a>
          </div>
          <figure class="media">
            @if (step?.videoUrl) {
              <div [attr.data-oembed-url]="step?.videoUrl">
                <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                  <iframe [src]="step?.videoUrl | sanitizeUrl"
                    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" frameborder="0"
                  allow="autoplay; encrypted-media" allowfullscreen=""></iframe>
                </div>
              </div>
            }
          </figure>
          @if (!step?.dateCompleted) {
            <div>
              <button (click)="skipStep()" mat-button matStepperNext>Skip</button>
            </div>
          }
        </div>
      </mat-step>
    }
  </mat-vertical-stepper>
</div>
}