<ng-template #sidenavRef>
  <hop-sidenav [collapsed]="sidenavCollapsed$ | async"></hop-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <hop-toolbar [hasShadow]="toolbarShadowEnabled$ | async" [mobileQuery]="!(isMedium$ | async)" [user]="user$ | async"
  class="hop-toolbar"></hop-toolbar>
</ng-template>

<ng-template #footerRef>
  @if ((isFooterVisible$ | async)) {
    <hop-footer class="hop-footer"></hop-footer>
  }
</ng-template>

<ng-template #quickpanelRef>
  <hop-quickpanel></hop-quickpanel>
</ng-template>

<ng-template #completeAccountRef>
  <hop-complete-account></hop-complete-account>
</ng-template>

<hop-layout [footerRef]="footerRef" [quickpanelRef]="quickpanelRef" [sidenavRef]="sidenavRef" [toolbarRef]="toolbarRef"
[completeAccountRef]="completeAccountRef"></hop-layout>

<hop-config-panel-toggle *ifFeature="FeatureEnum.isConfig" (openConfig)="configpanel.open()"></hop-config-panel-toggle>

<app-hop-complete-account-button *ifFeature="FeatureEnum.isAccountWizard"></app-hop-complete-account-button>

<!-- CONFIGPANEL -->
<hop-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <hop-config-panel></hop-config-panel>
</hop-sidebar>
<!-- END CONFIGPANEL -->