import { Calendar } from './calendar';
import { LanguageModel } from './language.model';
import { FileModel } from './file.model';
import { OfferAppointmentModel } from './appointment.model';

export class UserModel {
  uuid: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  needsPassword: boolean;
  isProfessional: boolean;
  imageFile: {
    name: string;
    url: string;
    uuid: string;
    isPublic: boolean;
  };
  lang?: LanguageModel;
  langCode: string;
  availabilityDefaultCalendar: Calendar;
  writeCalendar: Calendar;
  manyAdditionalLangs: LanguageModel[];
  country: any;
  _identifier_hash: any;
  dateFormat: 'DD_MM_YYYY' | 'MM_DD_YYYY';
  isPhoneVerified: boolean;
  phone: string;
  _needsToAcceptTerms?: boolean;
  isGuest: boolean;
  _termsAccepted: boolean;
  unconfirmedEmail: string;
  unconfirmedPhone: string;
}

export interface UserProfileModel {
  addressUrl: string;
  address: string;
  namePrefix: string;
  nameSuffix: string;
  _productsCount?: number;
  _productsCountCurrentLangCode?: number;
  _storiesCount?: number;
  _storiesCountCurrentLangCode?: number;
  welcomeMessage: string;
  imageUrl: string;
  videoUrl: string;
  uuid: string;
  bio: string;
  whatsApp: string;
  telegram: string;
  signal: string;
  phone: string;
  youtube: string;
  facebookUrl: string;
  instagramUrl: string;
  linkedInUrl: string;
  otherMessage: string;
  twitterUrl: string;
  websiteUrl: string;
  workCompany: string;
  workPosition: string;
  city: string;
  langCode: string;
  imageFile: FileModel;
  lang: LanguageModel;
  offerAppointments: OfferAppointmentModel[];
  user: UserModel;
  isAllowIndexing: boolean;
  _reviewsCount: number;
  _ratingAvg: number;
  reviewQuestions: string;
}
