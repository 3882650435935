@if (isLink(item) && !isFunction(item.route) && !item.disabled) {
  <a
    [fragment]="item.fragment"
    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
    [routerLink]="item.route"
    [queryParams]="item.queryParams"
    class="hop-sidenav-item"
    matRipple
    matRippleColor="var(--sidenav-item-ripple-color)"
    routerLinkActive="hop-sidenav-item--active">
    @if (level === 0) {
      <mat-icon [svgIcon]="item.icon" class="hop-sidenav-item__icon"></mat-icon>
    }
    <span class="hop-sidenav-item__label">{{ item.label }}</span>
    @if (item.badge) {
      <span
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
      class="hop-sidenav-item__badge">{{ item.badge.value }}</span>
    }
  </a>
}

@if (isLink(item) && isFunction(item.route)) {
  <div (click)="item.route()"
    class="hop-sidenav-item"
    matRipple
    matRippleColor="var(--sidenav-item-ripple-color)"
    routerLinkActive="hop-sidenav-item--active">
    @if (level === 0) {
      <mat-icon [svgIcon]="item.icon" class="hop-sidenav-item__icon"></mat-icon>
    }
    <span class="hop-sidenav-item__label">{{ item.label }}</span>
    @if (item.badge) {
      <span
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
      class="hop-sidenav-item__badge">{{ item.badge.value }}</span>
    }
  </div>
}

@if (isDropdown(item)) {
  <div (click)="toggleOpen()"
    [class.hop-sidenav-item--active]="isOpen || isActive"
    [class.hop-sidenav-item--open]="isOpen"
    class="hop-sidenav-item"
    matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    @if (level === 0) {
      <mat-icon [svgIcon]="item.icon" class="hop-sidenav-item__icon"></mat-icon>
    }
    <span class="hop-sidenav-item__label">{{ item.label }}</span>
    @if (item.badge) {
      <span
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
      class="hop-sidenav-item__badge">{{ item.badge.value }}</span>
    }
    <mat-icon class="hop-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="hop-sidenav-item__dropdown">
    @for (subItem of item.children; track subItem) {
      <hop-sidenav-item [item]="subItem" [hidden]='item.disabled' [level]="level + 1"></hop-sidenav-item>
    }
  </div>
}


@if (isSubheading(item)) {
  <div class="hop-sidenav-subheading">{{ item.label }}</div>
  @for (subItem of item.children; track subItem) {
    <hop-sidenav-item [item]="subItem" [level]="0" [hidden]='item.disabled'></hop-sidenav-item>
  }
}

