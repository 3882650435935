<div [formGroup]="form" class="flex flex-col w-full md:w-2/3">
  <p class="text-lg font-bold">{{"_comments.your-comment" | translate }}</p>
  <mat-form-field class="">
    <textarea rows="5" placeholder='{{"_comments._placeholder.body" | translate }}' data-testid="textarea-reason-cancel"
      matInput formControlName="body">
            </textarea>
    <mat-error><hop-mat-form-error control="body"></hop-mat-form-error></mat-error>
  </mat-form-field>
  <div class="self-start flex space-x-3">
    <button [disabled]="form?.invalid" type="button" class="px-5" style="height: 50px" (click)="submitComment()"
      mat-raised-button color="primary">
      {{"_comments.submit-comment" | translate }}
    </button>
    <button type="button" class="px-5" style="height: 50px" (click)="hideWriteComment()" mat-stroked-button
      color="primary">{{"_general.cancel" | translate }}</button>
  </div>
</div>