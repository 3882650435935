import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError } from 'rxjs';
import { PublicProfileModel } from '../models/public-profile.model';
import { PaginationFilterReviewsOptions } from '../models/pagination.model';

@Injectable({ providedIn: 'root' })
export class ReviewsService {
  endpoint = `${this.environment.apiUrl}/reviews`;

  constructor(private http: HttpClient, @Inject('environment') private environment) { }

  getAllReviews(): Observable<any> {
    return this.http.get<any>(this.endpoint).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  createReview(review): Observable<any> {
    return this.http.put<any>(this.endpoint, review).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  editReview(review): Observable<any> {
    return this.http.patch<any>(this.endpoint, review).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  removeReview(uuid): Observable<any> {
    return this.http.delete<any>(this.endpoint, { body: { uuid: uuid } }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getProductReviewsWithPagination(filter: any = {}, sortName = '', sortOrder = 'asc', page, limit = 10, productUuid): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/paginate/offset', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('page', page)
          .set('limit', limit)
          .set('productUuid', productUuid)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getMySubmittedReviewsWithPagination(
    options: PaginationFilterReviewsOptions = {
      filter: {}, sortName: '', sortOrder: 'asc', page: 1, limit: 10, userUuid: ''
    }): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/offset', {
        params: new HttpParams()
          .set('filter', JSON.stringify(options.filter))
          .set('sortName', options.sortName)
          .set('sortOrder', options.sortOrder)
          .set('page', options.page)
          .set('limit', options.limit)
          .set('userUuid', options.userUuid)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getProductReviewsWithCursor(cursorUuid, productUuid, filter = { body: '' }, sortName = 'id', sortOrder = 'desc', limit = 5): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/paginate/cursor', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('limit', limit)
          .set('cursorUuid', cursorUuid.toString())
          .set('productUuid', productUuid)
        // context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getUserReviewsWithCursor(cursorUuid, userUuid, filter = { body: '' }, sortName = 'id', sortOrder = 'asc', limit = 5): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/paginate/cursor', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('limit', limit)
          .set('cursorUuid', cursorUuid.toString())
          .set('userUuid', userUuid)
        // context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getProfessionalReviewsWithCursor(
    cursorUuid,
    professionalUuid,
    filter = { langCode: '' },
    sortName = 'id',
    sortOrder = 'desc',
    limit = 5
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/paginate/cursor', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('limit', limit)
          .set('cursorUuid', cursorUuid.toString())
          .set('professionalUuid', professionalUuid)
        // context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getProfessionalReviewsWithPagination(
    options: PaginationFilterReviewsOptions = {
      filter: {}, sortName: '', sortOrder: 'desc', page: 1, limit: 10, professionalUuid: ''
    }
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/paginate/offset', {
        params: new HttpParams()
          .set('filter', JSON.stringify(options.filter))
          .set('sortName', options.sortName)
          .set('sortOrder', options.sortOrder)
          .set('page', options.page)
          .set('limit', options.limit)
          .set('professionalUuid', options.professionalUuid)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
