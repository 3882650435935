import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.isActivatedSentry) {
  const meta = document.querySelector('meta[name="version"]');
  const release = meta ? meta.getAttribute('content') : 'no-version-found';
  Sentry.init({
    dsn: environment.sentryUrl,
    autoSessionTracking: false,
    sendClientReports: false,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      //Sentry.replayIntegration(),
      Sentry.dedupeIntegration()
      //new Dedupe()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/www.cosmoslevel\.com\/api/,
      /^https:\/\/www\.noti\.com\/api/,
      /^https:\/\/www-dev\.koschedule\.com\/api/
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    //replaysSessionSampleRate: 0.1,
    //replaysOnErrorSampleRate: 1.0,
    release,
    beforeSend(event) {
      //
      // Modify the event here
      if (['error', 'warning'].includes(event.level)) {
        event.tags = event.tags || {};
        event.tags.level = event.level;
        event.tags.APP_NAME = environment.name;
        event.tags.URL = window.location.href;
        event.tags.PATH = window.location.pathname;
        // Don't send !user's email address
        return event;
      }
      return null;
    }
  });
}

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
