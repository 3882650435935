import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { CommentModel } from 'src/@hop/models/comment.model';
import { CommentsService } from 'src/@hop/services/comments.service';
import { InViewService } from 'src/@hop/services/in-view.service';

@UntilDestroy()
@Component({
  selector: 'hop-all-comments',
  templateUrl: './all-comments.component.html',
  styleUrls: ['./all-comments.component.scss']
})
export class AllCommentsComponent implements OnInit, AfterViewInit {
  @Input() propertyServiceGet;
  @Input() comments: CommentModel[]
  @Input() reviewUuid: string;
  errorMessage: string;
  commentsMeta;
  @Output() commentDeleted = new EventEmitter();
  @Output() onScroll = new EventEmitter();
  @ViewChild('endRef') endRef: ElementRef;
  constructor(
    private inViewService: InViewService
  ) { }
  ngAfterViewInit(): void {
    this.inViewService
      .elementInSight(this.endRef, { rootMargin: '300px 0px 100px 0px' })
      .pipe(untilDestroyed(this))
      .subscribe((visible) => {
        if (visible) this.onScroll.next(null);
      });
  }

  ngOnInit(): void { }

  onCommentDelete(comment: CommentModel) {
    this.comments = this.comments.filter((r) => r.uuid !== comment.uuid);
  }

  removeComment(comment: CommentModel) {
    this.comments = this.comments.filter((r) => r.uuid !== comment.uuid);
  }
}
