import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../@hop/layout/layout.module';
import { CustomLayoutComponent } from './custom-layout.component';
import { SidenavModule } from '../../@hop/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@hop/layout/toolbar/toolbar.module';
import { FooterModule } from '../../@hop/layout/footer/footer.module';
import { ConfigPanelModule } from '../../@hop/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@hop/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@hop/layout/quickpanel/quickpanel.module';
import { IfFeatureFlagDirective } from '../../@hop/components/is-feature/is-feature.directive';
import { BasketViewModule } from 'src/@hop/components/basket-view/basket-view.module';
import { CompleteAccountModule } from "../../@hop/layout/complete-account/complete-account.module";
import { AppCompleteAccountButtonComponent } from 'src/@hop/components/hop-complete-account-button/hop-complete-account.component';

@NgModule({
  declarations: [CustomLayoutComponent],
  imports: [
    CommonModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    IfFeatureFlagDirective,
    BasketViewModule,
    CompleteAccountModule,
    AppCompleteAccountButtonComponent
  ]
})
export class CustomLayoutModule { }
