<hr class="my-4">

<div class="w-full flex flex-col space-y-3">
  <div class="flex justify-between">
    <div class="self-start flex flex-row space-x-3">
      <div class="relative">
        <img class="rounded-full h-16 w-16 justify-center m-auto object-contain"
          [src]="comment?.user?.imageFile?.url || '../../../../../assets/img/profile-image.png'" />
        @if (comment?.user?.username === username) {
          <mat-icon class="absolute bottom-1 left-0 text-2xl text-yellow-500"
          >star</mat-icon>
        }

      </div>

      <div class="flex flex-col self-center">
        <div class="flex space-x-1 items-center">

          <a [hopRouterLink]="'/@'+comment?.user?.username" class="username text-sm ">
            &#64;{{ comment?.user?.username }}
          </a>

        </div>
        <p class="">{{ timeUtilsService.formatDate(comment?._dateCreated, translateService) }}</p>
      </div>
    </div>
    <div class="flex space-x-3">
      @if (comment?._isCanEdit) {
        <button (click)="openEditDialogComment()" type="button"
          mat-icon-button><mat-icon>edit</mat-icon></button>
        }
        @if (comment?._isCanDelete) {
          <button (click)="deleteComment(comment)" type="button"
            mat-icon-button><mat-icon>delete</mat-icon></button>
          }

        </div>
      </div>

      <div class="w-full flex flex-col space-y-3">
        <div readMore="300px">
          <p style="overflow-wrap: anywhere" class="whitespace-pre-line">
            {{ comment?.body }}
            @if (comment?.isEdited) {
              <span class="text-gray text-sm">(edited)</span>
            }
          </p>
        </div>
        <div class="flex space-x-2 self-end">
          @if(comment?._isCanApprove){
            <button class="w-fit" (click)="approveOrDisapproveComment(!comment?.isApproved)" type="button" mat-button
              color="primary">
              @if(comment?.isApproved){
                <span class="text-red-400 flex items-center"><mat-icon class="mr-1">cancel</mat-icon> {{
                '_comments.disapprove' |
              translate}}</span>

              }@else {
              <span class="flex items-center"><mat-icon class="mr-1">check</mat-icon> {{ '_comments.approve' |
            translate}}</span>
          }
        </button>
      }
      @if (user && comment?.isApproved) {
        <button class="w-fit" (click)="replyToCommentToggle()" type="button"
          mat-button>
          <span><mat-icon> comment</mat-icon></span>
        {{'_comments.reply' | translate}}</button>
      }
    </div>


    @if (replyToComment) {
      <div>
        <hr class="my-4">
        <hop-write-comment (submittedComment)="addReplyToComment($event)" [commentParentUuid]="comment?.uuid"
        [reviewUuid]="reviewUuid" (onHideWriteComment)="replyToComment = false"></hop-write-comment>
      </div>
    }

    <div class="mt-5">
      @if (comment?._comments?.length) {
        <div class="flex items-start">
          @if (!showComments) {
            <div (click)="showComments=true" class="flex space-x-2 cursor-pointer items-center">
              <div class="w-4 h-4 border-l-2 border-t-2 border-gray-400 transform -rotate-45" style="margin-top: 0.1rem;">
              </div>
              <p>
                @if(comment?._comments?.length > 1){
                  {{'_comments.show-replies-count' | translate :{ count:comment?._comments?.length} }}
                } @else {
                  {{'_comments.show-reply' | translate}}
                }
              </p>
            </div>
          }
          @if (showComments) {
            <div (click)="showComments=false" class="flex space-x-2 cursor-pointer items-center">
              <div class="cursor-pointer w-4 h-4 border-l-2 border-t-2 border-gray-400 transform -rotate-90"></div>
              <p>{{"_comments.hide-replies" | translate }}</p>
            </div>
          }
        </div>
      }
      @for (childComment of comment._comments; track childComment) {
        <div class="md:ml-5">
          @if (showComments) {
            <hop-full-comment (commentDeleted)="removeComment($event)" [reviewUuid]="reviewUuid"
            class="w-full" [comment]="childComment"></hop-full-comment>
          }
        </div>
      }
    </div>
  </div>
</div>