import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NamesComponent } from './names.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormErrorsModule } from '../mat-form-errors/mat-form-errors.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [NamesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormErrorsModule,
    MatCheckboxModule
  ],
  exports: [NamesComponent]
})
export class NamesModule { }
