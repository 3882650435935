export enum FeatureEnum {
  isDebug = 'isDebug',
  isConfig = 'isConfig',
  isSearchHomePage = 'isSearchHomePage',
  isComingSoonPage = 'isComingSoonPage',
  isOfferAppointmentAllowQuests = 'isOfferAppointmentAllowQuests',
  isOfferAppointmentLimitMaxEventsPerDay = 'isOfferAppointmentLimitMaxEventsPerDay',
  isStripe = 'isStripe',
  isPhone = 'isPhone',
  isAccountWizard = 'isAccountWizard',
  isPreview = 'isPreview'
}

export interface FeatureModel {
  value: boolean;
  payload: string;
}

export type FeaturesType = {
  [key in FeatureEnum]: FeatureModel;
};

export class FeaturesModel implements FeaturesType {
  isComingSoonPage: FeatureModel;
  isConfig: FeatureModel;
  isDebug: FeatureModel;
  isOfferAppointmentAllowQuests: FeatureModel;
  isOfferAppointmentLimitMaxEventsPerDay: FeatureModel;
  isSearchHomePage: FeatureModel;
  isStripe: FeatureModel;
  isPhone: FeatureModel;
  isAccountWizard: FeatureModel;
  isPreview: FeatureModel;
  constructor(featureInput: Partial<FeaturesType>) {
    // for each FeatureEnum key create a new this[key] object with { value: false, payload: 'false' }
    for (const key in FeatureEnum) {
      if (Object.prototype.hasOwnProperty.call(FeatureEnum, key)) {
        this[FeatureEnum[key as keyof typeof FeatureEnum]] = { value: false, payload: 'false' };
      }
    }
    // for each key in featureInput, if this[key] is not undefined, set this[key] to value
    for (const [key, value] of Object.entries(featureInput)) {
      if (this[key as keyof typeof FeatureEnum] !== undefined) {
        this[key as keyof typeof FeatureEnum] = value;
      }
    }
  }
}
