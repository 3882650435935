import { Directive, ElementRef, Renderer2, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ColorSchemeName } from 'src/@hop/config/colorSchemeName';
import { Language } from 'src/@hop/models/language-model';
import { AppHopState } from 'src/@hop/state';

@UntilDestroy()
@Directive({
  selector: '[readMore]'
})
export class ViewMoreDirective {
  @Select(AppHopState.selectColorScheme) colorScheme$: Observable<ColorSchemeName>;
  @Select(AppHopState.selectLanguage) language$: Observable<Language>;
  @Input() readMore: string = '300px';
  button: HTMLButtonElement = this.renderer.createElement('button') as HTMLButtonElement;
  divFade: HTMLDivElement = this.renderer.createElement('div') as HTMLDivElement;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLDivElement>,
    private translateService: TranslateService,
    private ref: ChangeDetectorRef
  ) {
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
    this.addReadMoreButton();
    this.addFadeElement();
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.scrollHeight > parseInt(this.readMore)) {
      this.renderer.setStyle(this.el.nativeElement, 'height', this.readMore);
      this.renderer.setStyle(this.button, 'display', 'block');
      this.renderer.setStyle(this.divFade, 'display', 'block');
    }
  }

  addReadMoreButton() {
    this.renderer.setStyle(this.button, 'cursor', 'pointer');
    this.renderer.setStyle(this.button, 'position', 'absolute');
    this.renderer.setStyle(this.button, 'bottom', '0');
    this.renderer.setStyle(this.button, 'padding', '15px 0');
    this.renderer.setStyle(this.button, 'text-align', 'center');
    this.renderer.setStyle(this.button, 'width', '100%');
    this.renderer.setStyle(this.button, 'display', 'none');
    this.renderer.setStyle(this.button, 'z-index', '11');
    this.renderer.addClass(this.button, 'text-primary');

    this.translateService.stream('_general.read-more').subscribe((data) => {
      //console.warn(data);
      this.button.innerText = this.translateService.instant('_general.read-more');
      this.ref.markForCheck();
    });

    this.renderer.appendChild(this.el.nativeElement, this.button);

    this.button.addEventListener('click', () => {
      this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
      this.renderer.setStyle(this.button, 'display', 'none');
      this.renderer.setStyle(this.divFade, 'display', 'none');
    });
  }

  addFadeElement() {
    this.renderer.setStyle(this.divFade, 'position', 'absolute');
    this.renderer.setStyle(this.divFade, 'bottom', '0');
    this.renderer.setStyle(this.divFade, 'width', '100%');
    this.renderer.setStyle(this.divFade, 'height', '300px');
    this.renderer.setStyle(this.divFade, 'z-index', '10');
    this.colorScheme$.pipe(untilDestroyed(this)).subscribe((colorScheme) => {
      const isDarkMode = colorScheme === ColorSchemeName.dark;
      this.renderer.setStyle(this.divFade, 'background', `linear-gradient(to bottom, transparent,${isDarkMode ? '#424242' : 'white'})`);
    });
    // this.divFade.innerText = 'Read More';
    this.renderer.setStyle(this.divFade, 'display', 'none');
    this.renderer.appendChild(this.el.nativeElement, this.divFade);

    this.divFade.addEventListener('click', () => {
      this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
      this.renderer.setStyle(this.divFade, 'display', 'none');
      this.renderer.setStyle(this.button, 'display', 'none');
    });
  }
}
