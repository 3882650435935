import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ReviewsService } from 'src/@hop/services/review.service';

@Component({
  selector: 'hop-edit-review-component',
  templateUrl: './edit-review-component.component.html',
  styleUrls: ['./edit-review-component.component.scss']
})
export class EditReviewComponentComponent implements OnInit {
  isWriteReview = false;
  form;

  isEditMode = false;
  constructor(
    private fb: UntypedFormBuilder,
    private reviewsService: ReviewsService,
    private toastr: ToastrService,

    public dialogRef: MatDialogRef<EditReviewComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // if (this.review) {
    //   this.isEditMode = true;
    // }
    this.form = this.fb.group({
      rating: [this.data?.rating, Validators.required],
      body: [this.data?.body, Validators.required]
    });
  }

  async submitReview() {
    this.dialogRef.close({ reviewValue: this.form.value });

    // return await firstValueFrom(this.reviewsService.createReview({ ...this.form.value, product: { uuid: this.productUuid } }))
    // .then((result) => {
    //   this.submittedReview.next(null);
    //   this.toastr.success('Review added', '', { timeOut: 3000, progressBar: true });
    //   // this.router.navigate([`m/${result.uuid}`]);
    // })
    // .catch((error) => {
    //   if (error?.error) {
    //     this.toastr.error(error?.error?.message, 'Error sending review', { timeOut: 10000, progressBar: true });
    //   }
    // });
  }

  dialogTitle: string;

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirmDelete() {
    this.dialogRef.close({ confirmDelete: true });
  }
}
