import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../../@hop/services';
import * as cookie from 'js-cookie';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        return this.handleResponseError(err, request, next);
      })
    );
  }

  handleResponseError(error, request?, next?): Observable<HttpEvent<any>> {
    // Business error
    if (error.status === 400) {
      // Show message
    }

    // Invalid token error
    else if (error.status === 401) {
      this.authenticationService.jwtToken$.next(null);
      // @ts-ignore
      return this.authenticationService.refreshToken().pipe(
        switchMap(() => {
          if (this.authenticationService.jwtToken) {
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${this.authenticationService.jwtToken}` }
            });
            return next.handle(request);
          }
          this.authenticationService.logout();
          return throwError(error);
        }),
        catchError(() => {
          this.authenticationService.logout();
          return throwError(error);
        })
      );
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      // Logout
      this.authenticationService.logout();
    }

    // token revoked
    //else if (error.status === 422) {
    //this.toastrService.warning(error.error.message);
    // Show message
    // Logout
    // this.authenticationService.logout();
    //}

    // Server error
    else if (error.status === 500) {
      // Show message
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
    }

    // this.authenticationService.logout();
    console.error(error);
    return throwError(error);
  }
}
