<div #originRef (click)="showPopover(originRef)" [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple data-testid="toolbar-user">
  <!--<div class="nameToolbar body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden md:block">{{ user?.firstName }} {{ user?.lastName }}</div>-->
  <div class="h-9 w-9 flex items-center justify-center text-primary">
    <img class="rounded-full w-9 h-9 justify-center m-auto relative"
      [src]="user?.imageFile?.url ? user?.imageFile?.url : environment.mainUrl + '/assets/img/profile.png'" />
    @if (user?.isProfessional) {
      <mat-icon class="absolute bottom-1 left-0 text-2xl text-yellow-500">star</mat-icon>
    }
  </div>
</div>